import React, { useMemo } from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';

import useCommonStore from 'stores/useCommon';
import PAGES, { SUB_MENU_HEADER } from 'routes/constants';
import 'styles/common.scss';

export const WITHOUT_NAVIGATION_BAR_URL = [PAGES.nightAudit, PAGES.travelAgency, PAGES.detailGroup];

function NavigateBar() {
  const location = useLocation();
  const { activeMenuId, activeMenuManageId } = useCommonStore();

  const withoutNavigateBar = useMemo(() => {
    if (WITHOUT_NAVIGATION_BAR_URL.some(item => matchPath(item, location.pathname))) {
      return true;
    }
    return false;
  }, [location.pathname]);

  const subMenuHeader = useMemo(() => {
    return SUB_MENU_HEADER[activeMenuId] || SUB_MENU_HEADER[activeMenuManageId];
  }, [activeMenuId, activeMenuManageId]);

  if ((!activeMenuId && !activeMenuManageId) || withoutNavigateBar) {
    return null;
  }

  return (
    <div className="pms-navigate-bar">
      <ul className="pms-page-menu">
        {subMenuHeader &&
          subMenuHeader.map((item, index) => (
            <li className={matchPath(item.url, location.pathname) ? 'active' : ''} key={index}>
              <Link to={item.url}>{item.label}</Link>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default NavigateBar;
