import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import useCommonStore from 'stores/useCommon';
import { formatCurrency, toLocalTime } from 'utils';
import { CASHFLOW_TYPE } from 'constants/common';
import { useGetCashFlowAll } from 'hooks/useGetCashFlow';

interface Props {
  fileName: string;
  filterOptions: any;
}

const ExportCashFlow = ({ fileName, filterOptions }: Props) => {
  const { currentBranch } = useCommonStore();

  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const { data: dataAll } = useGetCashFlowAll(filterOptions);

  const totalIncome = useMemo(() => {
    return dataAll.reduce((cur, next) => {
      return cur + (next.payment_type === CASHFLOW_TYPE.INCOME ? next.amount : 0);
    }, 0);
  }, [dataAll]);

  const totalOutcome = useMemo(() => {
    return dataAll.reduce((cur, next) => {
      return cur + (next.payment_type === CASHFLOW_TYPE.PAYMENT ? next.amount : 0);
    }, 0);
  }, [dataAll]);

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = async () => {
    try {
      setIsLoadingExport(true);
      const dataToExport = dataAll.map((item, index) => {
        return {
          stt: index + 1,
          payment_date: toLocalTime(item.payment_date, 'DD/MM/YYYY HH:mm'),
          income_id: item.payment_type === CASHFLOW_TYPE.INCOME ? `PTM${item.id}` : '',
          outcome_id: item.payment_type === CASHFLOW_TYPE.PAYMENT ? `PTM${item.id}` : '',
          note: item.note || '',
          income: item.payment_type === CASHFLOW_TYPE.INCOME ? item.amount : 0,
          outcome: item.payment_type === CASHFLOW_TYPE.PAYMENT ? item.amount : 0,
          user_name: item.user_name
        };
      });

      // Sheet name
      const workbook = new ExcelJS.Workbook();
      const sheetName = `Report`;
      const sheet = workbook.addWorksheet(sheetName);

      // Title
      sheet.mergeCells('A1:H1');
      sheet.getCell('A1').value = currentBranch?.name?.toUpperCase() || '';
      sheet.getCell('A1').font = { bold: true, size: 16 };

      // Address
      sheet.mergeCells('A2:H2');
      sheet.getCell('A2').value = `Địa chỉ: ${currentBranch?.address}`;
      sheet.getCell('A2').font = { size: 12 };

      // Support
      sheet.mergeCells('A3:H3');
      sheet.getCell('A3').value = currentBranch?.phone_contact
        ? `ĐT: ${currentBranch?.phone_contact}`
        : '';
      sheet.getCell('A3').font = { size: 12 };

      // Support subtitle
      sheet.mergeCells('A5:H5');
      sheet.getCell('A5').value = 'Sổ chi tiết tiền mặt';
      sheet.getCell('A5').font = { bold: true, size: 16 };
      sheet.getCell('A5').alignment = { horizontal: 'center' };
      sheet.mergeCells('A6:H6');
      sheet.getCell('A6').value = `Từ ngày ${dayjs
        .utc(filterOptions.start_date)
        .local()
        .format('DD-MM-YYYY')} - Đến ngày ${dayjs
        .utc(filterOptions.end_date)
        .local()
        .format('DD-MM-YYYY')}`;
      sheet.getCell('A6').font = { bold: true, size: 12 };
      sheet.getCell('A6').alignment = { horizontal: 'center' };

      // Header columns
      sheet.mergeCells('A7:A8');
      sheet.getCell('A7').value = 'STT';
      sheet.getCell('A7').font = { bold: true, size: 11 };
      sheet.getCell('A7').alignment = {
        horizontal: 'center',
        vertical: 'middle'
      };

      sheet.mergeCells('B7:B8');
      sheet.getCell('B7').value = 'Ngày';
      sheet.getCell('B7').font = { bold: true, size: 11 };
      sheet.getCell('B7').alignment = {
        horizontal: 'center',
        vertical: 'middle'
      };

      sheet.mergeCells('C7:C8');
      sheet.getCell('C7').value = 'Số phiếu thu';
      sheet.getCell('C7').font = { bold: true, size: 11 };
      sheet.getCell('C7').alignment = {
        horizontal: 'center',
        vertical: 'middle'
      };

      sheet.mergeCells('D7:D8');
      sheet.getCell('D7').value = 'Số phiếu chi';
      sheet.getCell('D7').font = { bold: true, size: 11 };
      sheet.getCell('D7').alignment = {
        horizontal: 'center',
        vertical: 'middle'
      };

      sheet.mergeCells('E7:E8');
      sheet.getCell('E7').value = 'Thu chi';
      sheet.getCell('E7').font = { bold: true, size: 11 };
      sheet.getCell('E7').alignment = {
        horizontal: 'center',
        vertical: 'middle'
      };

      sheet.getCell('F7').value = 'Mục thu';
      sheet.getCell('F7').font = { bold: true, size: 11 };
      sheet.getCell('F7').alignment = { horizontal: 'center' };
      sheet.getCell('F8').value = 'VND';
      sheet.getCell('F8').font = {
        bold: true,
        size: 11,
        color: { argb: 'FF4CAF50' }
      };
      sheet.getCell('F8').alignment = { horizontal: 'center' };

      sheet.getCell('G7').value = 'Mục chi';
      sheet.getCell('G7').font = { bold: true, size: 11 };
      sheet.getCell('G7').alignment = { horizontal: 'center' };
      sheet.getCell('G8').value = 'VND';
      sheet.getCell('G8').font = {
        bold: true,
        size: 11,
        color: { argb: 'FFFF5722' }
      };
      sheet.getCell('G8').alignment = { horizontal: 'center' };

      sheet.mergeCells('H7:H8');
      sheet.getCell('H7').value = 'Người tạo';
      sheet.getCell('H7').font = { bold: true, size: 11 };
      sheet.getCell('H7').alignment = {
        horizontal: 'center',
        vertical: 'middle'
      };

      // Data
      sheet.columns = [
        {
          key: 'stt',
          width: 10,
          style: { alignment: { horizontal: 'center' } }
        },
        { key: 'payment_date', width: 30 },
        {
          key: 'income_id',
          width: 12,
          style: { font: { color: { argb: 'FF4CAF50' } } }
        },
        {
          key: 'outcome_id',
          width: 12,
          style: { font: { color: { argb: 'FFFF5722' } } }
        },
        { key: 'note', width: 80 },
        {
          key: 'income',
          width: 15,
          style: { font: { color: { argb: 'FF4CAF50' } } }
        },
        {
          key: 'outcome',
          width: 15,
          style: { font: { color: { argb: 'FFFF5722' } } }
        },
        {
          key: 'user_name',
          width: 30,
          style: { alignment: { horizontal: 'center' } }
        }
      ];

      dataToExport.forEach(item => {
        sheet.addRow(item);
      });

      // 9 is the first row of data
      const lastRow = 9 + dataToExport.length;
      let summaryRow = lastRow + 1;

      sheet.mergeCells(`A${summaryRow}:H${summaryRow}`);
      sheet.getCell(`A${summaryRow}`).value = `I. Số dư đầu kỳ ngày: VND 0`;
      sheet.getCell(`A${summaryRow}`).font = { bold: true, size: 11 };
      sheet.getCell(`A${summaryRow}`).alignment = { horizontal: 'left' };

      summaryRow += 1;
      sheet.mergeCells(`A${summaryRow}:H${summaryRow}`);
      sheet.getCell(`A${summaryRow}`).value =
        `II. Tổng tiền thu đối chiếu trong kỳ: ${formatCurrency(totalIncome, 'VND')}`;
      sheet.getCell(`A${summaryRow}`).font = { bold: true, size: 11 };
      sheet.getCell(`A${summaryRow}`).alignment = { horizontal: 'left' };

      summaryRow += 1;
      sheet.mergeCells(`A${summaryRow}:H${summaryRow}`);
      sheet.getCell(`A${summaryRow}`).value =
        `III. Tổng tiền chi đối chiếu trong kỳ:${formatCurrency(totalOutcome, 'VND')}`;
      sheet.getCell(`A${summaryRow}`).font = { bold: true, size: 11 };
      sheet.getCell(`A${summaryRow}`).alignment = { horizontal: 'left' };

      summaryRow += 1;
      sheet.mergeCells(`A${summaryRow}:H${summaryRow}`);
      sheet.getCell(`A${summaryRow}`).value = `Cuối kỳ (I+II-III): ${formatCurrency(
        totalIncome - totalOutcome,
        'VND'
      )}`;
      sheet.getCell(`A${summaryRow}`).font = { bold: true, size: 11 };
      sheet.getCell(`A${summaryRow}`).alignment = { horizontal: 'left' };

      const buffer = await workbook.xlsx.writeBuffer();
      const data = new Blob([buffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (e) {
      console.log('ERROR', e);
    } finally {
      setIsLoadingExport(false);
    }
  };

  return (
    <Button icon={<DownloadOutlined />} onClick={exportToCSV} loading={isLoadingExport}>
      Xuất dữ liệu
    </Button>
  );
};

export default ExportCashFlow;
