import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Dropdown, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import classNames from 'classnames';

import useModal from 'stores/useModal';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import { toLocalTime } from 'utils';
import { ACTION_BOOKING, ACTION_CLEANING_CALENDAR } from 'constants/table';

import useBookingStore from 'stores/useBooking';

import { cleanRoom } from 'services/api/module/room.api';

import { BookingStatus } from 'services/api/type/booking.type';
import { ReactComponent as IconFix } from 'assets/images/fix.svg';
import { ReactComponent as IconDirty } from 'assets/images/ic_house_keeping_dirty_no_fill.svg';
import { ROOM_STATUS_NAMES } from 'constants/form';
import IconNote from 'assets/images/ic_note_room.svg';

interface RoomItemProps {
  data: any;
  roomId: number;
  roomNo: string;
}

function CalendarItem({ data, roomId, roomNo }: RoomItemProps) {
  const bookingLineId = _.get(data, 'booking_line.booking_line_id', '');
  const {
    setInfoConfirmModal,
    setIsOpenAddService,
    setIsOpenFixRoom,
    setConfirmLoading,
    setOpenBookingDetail
  } = useModal();
  const { setBookingLineId, setRoom } = useBookingStore();

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);

  const { mutateAsync: mutateDirtyRoom } = useMutation({
    mutationFn: ({ ids, is_clean }: { ids: number[]; is_clean: boolean }) => {
      return cleanRoom(ids, is_clean);
    }
  });

  const handleClickMenu = async (menu: any) => {
    switch (menu.key) {
      case ACTION_BOOKING.DETAIL:
        setOpenBookingDetail(true, bookingLineId);
        break;
      case ACTION_BOOKING.DIRTY_ROOM:
        setInfoConfirmModal(true, {
          title: 'Xác nhận phòng bẩn',
          onOk: () => {
            setInfoConfirmModal(false);
            handleMakeDirtyOrClean(roomId);
          }
        });
        break;
      case ACTION_BOOKING.CLEAN_ROOM:
        setInfoConfirmModal(true, {
          title: 'Xác nhận phòng sạch',
          onOk: () => {
            setInfoConfirmModal(false);
            handleMakeDirtyOrClean(roomId, true);
          }
        });
        break;
      case ACTION_BOOKING.ADD_SERVICE:
        setBookingLineId(Number(bookingLineId));
        setIsOpenAddService(true);
        break;

      case ACTION_BOOKING.FIX_ROOM: {
        setRoom(roomId, roomNo, data.room_lock);
        setIsOpenFixRoom(true);
        break;
      }
      default:
        break;
    }
  };

  const handleMakeDirtyOrClean = async (roomId: number, isClean: boolean = false) => {
    setConfirmLoading(true);
    await mutateDirtyRoom({
      ids: [roomId],
      is_clean: isClean
    });

    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_CLEANING_CALENDAR]
    });

    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL, bookingLineId]
    });
    setConfirmLoading(false);
  };

  const handleChangeOpenDropdown = (value: boolean) => {
    setIsOpenDropdown(value);
    if (value) {
      setIsOpenTooltip(false);
    }
  };

  const handleChangeOpenTooltip = (value: boolean) => {
    if (!isOpenDropdown) {
      setIsOpenTooltip(value);
    }
  };

  const calculateNight = (checkIn: string, checkOut: string) => {
    let startDate: Dayjs = dayjs.utc(checkIn).local();
    let endDate: Dayjs = dayjs.utc(checkOut).local();
    if (startDate) {
      startDate = startDate.set('hour', 0).set('minute', 0).set('second', 0);
    }
    if (endDate) {
      endDate = endDate.set('hour', 23).set('minute', 59).set('second', 59);
    }
    return endDate.diff(startDate, 'd');
  };

  const menuActionFix = useMemo(() => {
    return ACTION_CLEANING_CALENDAR?.filter(item => item?.key === ACTION_BOOKING.FIX_ROOM) || [];
  }, []);

  const menuActions = useMemo(() => {
    const isClean = _.get(data, 'booking_line.is_clean');

    return (
      ACTION_CLEANING_CALENDAR?.filter(
        item =>
          (isClean
            ? item?.key !== ACTION_BOOKING.CLEAN_ROOM
            : item?.key !== ACTION_BOOKING.DIRTY_ROOM) && item?.key !== ACTION_BOOKING.FIX_ROOM
      ) || []
    );
  }, [data]);

  const note = _.get(data, 'booking_line.note', '');
  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: !_.isEmpty(data?.room_lock) ? menuActionFix : menuActions,
        onClick: handleClickMenu
      }}
      placement="bottom"
      overlayClassName="action-booking-list"
      destroyPopupOnHide
      onOpenChange={handleChangeOpenDropdown}
    >
      <Tooltip
        open={isOpenTooltip}
        onOpenChange={handleChangeOpenTooltip}
        overlayClassName="cleaning-item__tooltip-overlay"
        title={
          data?.status === 'LOCKED' ? (
            <>
              <div className="pms-room-item__tooltip-header__customer">
                <span className="flex items-center">
                  <IconFix /> Sửa phòng
                </span>
              </div>
              <div className="pms-room-item__tooltip-header__timeline">
                {data.room_lock?.start_date
                  ? toLocalTime(data.room_lock?.start_date, 'DD/MM HH:mm')
                  : ''}{' '}
                -{' '}
                {data.room_lock?.end_date
                  ? toLocalTime(data.room_lock?.end_date, 'DD/MM HH:mm')
                  : ''}
              </div>
              <div
                className="pms-room-item__tooltip-body flex items-center"
                style={{ borderBottom: 0 }}
              >
                <img src={IconNote} alt="note" />
                <span>{data.room_lock?.reason}</span>
              </div>
            </>
          ) : (
            <div>
              <p className="m-0">Khách: {data?.booking_line?.partner_name}</p>
              <p className="m-0">Mã đặt phòng: {data?.booking_line?.booking_id}</p>
              <p className="m-0">
                Sẽ đến: {toLocalTime(data?.booking_line?.check_in, 'DD/MM/YYYY HH:mm')}
              </p>
              <p className="m-0">
                Sẽ đi: {toLocalTime(data?.booking_line?.check_out, 'DD/MM/YYYY HH:mm')}
              </p>
              <p className="m-0">
                Tổng số đêm:{' '}
                {calculateNight(data?.booking_line?.check_in, data?.booking_line?.check_out)}
              </p>
              {note && <p className="m-0">Ghi chú: {note}</p>}
            </div>
          )
        }
        arrow={false}
      >
        <div
          className={classNames('w-full', {
            'cleaning-status': data.status,
            allot: data?.booking_line?.status === BookingStatus.CHECK_IN,
            initial: data?.booking_line?.status === BookingStatus.CONFIRM,
            locked: data?.status === 'LOCKED'
          })}
          style={{ width: '120px' }}
        >
          <span>{ROOM_STATUS_NAMES[data.status]}</span>
          {!_.get(data, 'booking_line.is_clean', true) ? <IconDirty /> : null}
        </div>
      </Tooltip>
    </Dropdown>
  );
}

export default CalendarItem;
