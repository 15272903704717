import _ from 'lodash';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';
import { RoomDetailStatusPicked } from 'services/api/type/booking.type';

export const useGetBookingTimeline = (start_date: string, end_date: string, isEnable: boolean) => {
  const { data, status, isLoading, isFetching, isFetchedAfterMount } = useQuery({
    queryKey: [QUERY_KEYS.GET_BOOKING_TIMELINE, start_date, end_date],
    queryFn: () => API.booking.getBookingTimeline(start_date, end_date),
    enabled: isEnable
  });

  const dataRooms: {
    [key: number]: RoomDetailStatusPicked;
  } = useMemo(() => {
    const rooms: RoomDetailStatusPicked[] = _.get(data, 'rooms', []);
    return rooms.reduce((cur: RoomDetailStatusPicked, next: RoomDetailStatusPicked) => {
      return {
        ...cur,
        [next.room_id]: next
      };
    }, {} as RoomDetailStatusPicked);
  }, [data]);

  return {
    data: _.get(data, 'bookings', []),
    dataRooms,
    dataLocks: _.get(data, 'locks', []),
    status,
    isLoading,
    isFetching,
    isFetchedAfterMount
  };
};
