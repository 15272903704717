import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { Button, Card, DatePicker, Select, Form, FormProps, Table, Input } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useQueryClient } from '@tanstack/react-query';

import { useGetAllPayments } from 'hooks/useGetPayments';

import QUERY_KEYS from 'services/api/queryKeys';
import { PAYMENT_METHOD_FILTER_OPTIONS, PAYMENT_STATE_OPTIONS } from 'constants/form';

import { columnTransactions } from 'constants/table';
import { PAGE_SIZE_TRANSACTION } from 'services/api/module/report.api';
import { toUtcTime } from 'utils';
import 'styles/transactions.scss';

const initStartDate = dayjs().set('hour', 0).set('minute', 0).set('second', 0);
const initEndDate = dayjs().set('hour', 23).set('minute', 59).set('second', 59);

function Transactions() {
  const [form] = Form.useForm();

  const queryClient = useQueryClient();
  const isIpadMobile = useMediaQuery({
    query: '(max-width: 991px)'
  });

  const [filterOptions, setFilterOptions] = useState<any>({
    start_date: toUtcTime(initStartDate, 'YYYY-MM-DD HH:mm:ss'),
    end_date: toUtcTime(initEndDate, 'YYYY-MM-DD HH:mm:ss'),
    state: '',
    payment_method: ''
  });

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_TRANSACTION);

  const {
    data: transactions,
    pagination,
    isFetching
  } = useGetAllPayments(filterOptions, page, pageSize);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_TRANSACTIONS, page]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});

    const { rangeDate, ...restFilterOptions } = truthyValues;

    if (rangeDate) {
      const startDate: Dayjs = rangeDate[0];
      const endDate: Dayjs = rangeDate[1];
      if (startDate) {
        _.set(restFilterOptions, 'start_date', toUtcTime(startDate, 'YYYY-MM-DD HH:mm:ss'));
      }
      if (endDate) {
        _.set(restFilterOptions, 'end_date', toUtcTime(endDate, 'YYYY-MM-DD HH:mm:ss'));
      }
    }

    if (page !== 1) {
      setPage(1);
    }
    setFilterOptions(restFilterOptions);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  return (
    <div className="pms-transactions">
      <Card title="Danh sách giao dịch" style={{ width: '100%' }}>
        <Form
          form={form}
          name="staying-guests-form"
          layout={isIpadMobile ? 'vertical' : 'inline'}
          initialValues={{
            rangeDate: [initStartDate, initEndDate],
            state: '',
            payment_method: ''
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="pms-transactions__filter-wrapper">
            <div className="pms-transactions__filter">
              <Form.Item name="rangeDate">
                <DatePicker.RangePicker
                  showTime
                  className="w-full"
                  placeholder={['Từ ngày', 'Đến ngày']}
                  format="YYYY-MM-DD HH:mm"
                  // onChange={handleRangeDateChange}
                />
              </Form.Item>

              <Form.Item name="booking_id">
                <Input placeholder="Mã đặt phòng" />
              </Form.Item>

              <Form.Item name="state">
                <Select
                  options={PAYMENT_STATE_OPTIONS}
                  labelRender={option => <span>Trạng thái: {option.label}</span>}
                />
              </Form.Item>

              <Form.Item name="payment_method">
                <Select
                  options={PAYMENT_METHOD_FILTER_OPTIONS}
                  labelRender={option => <span>Phương thức thanh toán: {option.label}</span>}
                />
              </Form.Item>

              <Button htmlType="submit" className="ant-btn-secondary btn-submit">
                Tìm kiếm
              </Button>
            </div>
          </div>
        </Form>

        <div className="pms-transactions__table">
          <Table
            loading={isFetching}
            rowKey="id"
            columns={columnTransactions}
            dataSource={transactions}
            // onRow={(record, rowIndex) => {
            //   return {
            //     }, // click row
            //   };
            // }}
            pagination={{
              current: page,
              position: ['bottomCenter'],
              total: pagination.total,
              pageSize: pageSize,
              locale: { items_per_page: '/ trang' },
              showTotal: total => `${total} items`,
              onChange: (page: number) => handleChangePage(page),
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              }
            }}
            locale={{
              emptyText: <span className="empty-data">Không có dữ liệu</span>
            }}
            scroll={{ x: 500, y: 'calc(100vh - 400px)' }}
          />
        </div>
      </Card>
    </div>
  );
}

export default Transactions;
