import { getEndOfTommorow, getStartOfRightDay } from 'utils';
import CustomTable from './CustomTable';
import { getColumnRoomLock } from 'constants/table';
import { useGetRoomLock } from 'hooks/useGetRoomLock';

interface RoomLockedProps {
  isEnable: boolean;
  dateToQuery: any;
  roomTypeToQuery: string;
}

export default function RoomLockedTab({ isEnable, dateToQuery, roomTypeToQuery }: RoomLockedProps) {
  const { data: roomLocks, isLoading } = useGetRoomLock(
    {
      start_date: getStartOfRightDay(dateToQuery).format('YYYY-MM-DD HH:mm:ss'),
      end_date: getEndOfTommorow(dateToQuery).format('YYYY-MM-DD HH:mm:ss'),
      short_code: roomTypeToQuery
    },
    isEnable
  );

  return (
    <CustomTable
      rowKey="room_id"
      loading={isLoading}
      columns={getColumnRoomLock()}
      dataSource={roomLocks || []}
    />
  );
}
