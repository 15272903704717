import React, { useEffect, useState } from 'react';
import {
  List,
  Modal,
  Space,
  Tag,
  Form,
  DatePicker,
  Select,
  Button,
  Typography,
  Spin,
  Empty
} from 'antd';
import { Log } from 'services/api/type/booking.type';
import useModal from 'stores/useModal';
import { useGetTrackingLogs } from 'hooks/useGetTrackingLogs';
import dayjs from 'dayjs';
import { toLocalTime } from 'utils';

const { Option } = Select;
const { Text } = Typography;

const types: { [key: string]: string } = {
  booking_payment_add: 'Thêm thanh toán',
  booking_cash_payment_add: 'Thêm phiếu thu',
  booking_payment_removed: 'Xóa thanh toán',
  booking_payment_refunded: 'Nhận lại tiền',
  booking_checkin: 'Nhận phòng',
  booking_checkout: 'Trả phòng',
  booking_undo_checkin: 'Hoàn tác nhận phòng',
  booking_undo_checkout: 'Hoàn tác trả phòng',
  booking_booked: 'Đặt phòng',
  booking_cancel: 'Hủy phòng',
  booking_change_price: 'Thay đổi giá tiền',
  booking_change_date: 'Thay đổi ngày ở',
  booking_change_room: 'Đổi phòng',
  booking_assign_room: 'Gán phòng',
  booking_change_room_type: 'Đổi hạn phòng'
};

interface FilterFormValues {
  date?: dayjs.Dayjs;
  logType?: string[];
}

function TrackingLogsModal() {
  const [form] = Form.useForm();
  const { isOpenViewTrackingLogs, setIsOpenViewTrackingLogs, bookingLineId } = useModal();
  const { data: trackingLogs, isLoading, refetch } = useGetTrackingLogs(bookingLineId);
  const [filteredLogs, setFilteredLogs] = useState<{ [key: string]: Log[] }>({});

  useEffect(() => {
    if (isOpenViewTrackingLogs) {
      refetch();
    }
  }, [isOpenViewTrackingLogs, refetch]);

  useEffect(() => {
    if (trackingLogs) {
      const groupedLogs: { [key: string]: Log[] } = {};

      trackingLogs.forEach((log: Log) => {
        const dateKey = toLocalTime(log.create_date, 'YYYY-MM-DD');
        if (groupedLogs[dateKey]) {
          groupedLogs[dateKey].push(log);
        } else {
          groupedLogs[dateKey] = [log];
        }
      });

      setFilteredLogs(groupedLogs);
    }
  }, [trackingLogs]);

  const handleCancel = () => {
    form.resetFields();
    setFilteredLogs({});
    setIsOpenViewTrackingLogs(false);
  };

  const handleFinish = (values: FilterFormValues) => {
    const { date, logType } = values;
    const filteredLogType = logType && logType.length ? logType : Object.keys(types);
    const filtered: { [key: string]: Log[] } = {};

    trackingLogs?.forEach((log: Log) => {
      const matchDate = date ? dayjs(toLocalTime(log.create_date)).isSame(date, 'date') : true;
      const matchLogType = filteredLogType.includes(log.type);

      if (matchDate && matchLogType) {
        const dateKey = toLocalTime(log.create_date, 'YYYY-MM-DD');
        if (filtered[dateKey]) {
          filtered[dateKey].push(log);
        } else {
          filtered[dateKey] = [log];
        }
      }
    });

    setFilteredLogs(filtered);
  };

  return (
    <Modal
      title="Nhật ký hoạt động"
      className="modal-view-tracking-logs"
      centered
      width={1000}
      open={isOpenViewTrackingLogs}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
    >
      <div style={{ position: 'relative', overflowX: 'hidden' }}>
        <Spin spinning={isLoading}>
          <Form
            form={form}
            layout="inline"
            onFinish={handleFinish}
            style={{
              position: 'sticky',
              zIndex: 1,
              background: '#fff',
              padding: '10px 20px',
              borderBottom: '1px solid #f0f0f0'
            }}
          >
            <Form.Item name="date" label="Ngày">
              <DatePicker placeholder="Chọn ngày" style={{ width: 200 }} format="DD-MM-YYYY" />
            </Form.Item>
            <Form.Item name="logType" label="Hành động">
              <Select mode="multiple" placeholder="Chọn hành động" style={{ minWidth: 200 }}>
                {Object.keys(types).map(key => (
                  <Option key={key} value={key}>
                    {types[key]}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Tìm kiếm
              </Button>
            </Form.Item>
          </Form>
        </Spin>

        {Object.keys(filteredLogs).length === 0 ? (
          <Empty description="Không có dữ liệu phù hợp." />
        ) : (
          <div style={{ maxHeight: '60vh', overflowY: 'auto', padding: '10px 20px' }}>
            {Object.keys(filteredLogs).map(dateKey => (
              <div key={dateKey} style={{ marginBottom: 20 }}>
                <div
                  style={{
                    width: '880px',
                    padding: 5
                  }}
                >
                  <Typography.Title level={5}>
                    {toLocalTime(dateKey, 'DD/MM/YYYY')}
                  </Typography.Title>

                  <List
                    itemLayout="vertical"
                    dataSource={filteredLogs[dateKey]}
                    renderItem={(log: Log) => (
                      <List.Item key={log.id} style={{ paddingLeft: 40 }}>
                        <Space direction="vertical">
                          <Text strong>
                            {toLocalTime(log.create_date, 'HH:mm:ss')}{' '}
                            <Tag color="blue">{types[log.type] || log.type}</Tag>
                          </Text>
                          <Text>
                            {log.user_email}: {log.log}
                          </Text>
                        </Space>
                      </List.Item>
                    )}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
}
export default TrackingLogsModal;
