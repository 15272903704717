import React, { useMemo } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import {
  Text,
  View,
  Page,
  Document,
  StyleSheet,
  Svg,
  Path,
  Font,
  Image
} from '@react-pdf/renderer';
import { BookingLine } from 'services/api/type/booking.type';
import { formatCurrency, toLocalTime } from 'utils';
// import { PAYMENT_METHOD_NAMES, PAYMENT_STATUS } from "constants/form";
// import { currencyFormatter } from "utils/currency";

// Register font
Font.register({
  family: 'Inter',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf',
      fontWeight: 100
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf',
      fontWeight: 200
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf',
      fontWeight: 300
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
      fontWeight: 400
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf',
      fontWeight: 500
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf',
      fontWeight: 600
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf',
      fontWeight: 700
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf',
      fontWeight: 800
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf',
      fontWeight: 900
    }
  ]
});

const borderColor = '#000';
const styles = StyleSheet.create({
  page: {
    fontSize: 12,
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    lineHeight: 1.5,
    flexDirection: 'column'
  },

  spaceBetween: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#000'
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px'
  },
  center: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  square: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: 'black',
    width: 20,
    height: 20
  },

  headerContainer: { flexDirection: 'column', marginBottom: 4 },
  headerLogo: { flexDirection: 'row', justifyContent: 'center' },

  tableContainer: {
    flexDirection: 'column',
    // flexWrap: "wrap",
    marginTop: 4,
    borderWidth: 1,
    borderColor: borderColor,
    borderBottom: 0,
    borderRight: 0
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    minHeight: 28
  },
  tableCell: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    color: '#000',
    padding: '6px 8px'
  },

  signatureContainer: {
    flexDirection: 'row',
    marginTop: 28,
    justifyContent: 'space-evenly'
  },
  signatureItem: {
    flexDirection: 'column',
    rowGap: '8px'
  },
  image: {
    width: 60,
    height: 50,
    marginTop: 10
  },

  logo: { width: 90 },
  text: { fontSize: 10, fontWeight: 500, fontFamily: 'Inter' },
  textExSmallBold: { fontSize: 9, fontWeight: 600, fontFamily: 'Inter' },
  textBold: { fontSize: 13, fontWeight: 600, fontFamily: 'Inter' },
  textSmallBold: { fontSize: 11, fontWeight: 600, fontFamily: 'Inter' }
});

interface Props {
  dataChange: any;
  bookingLineDetail: BookingLine | undefined;
  signatureStaff: string;
  signatureGuest: string;
}

export default function ConfirmationPDF({
  dataChange,
  bookingLineDetail,
  signatureStaff,
  signatureGuest
}: Props) {
  const firstName = useMemo(() => {
    const arrWords = bookingLineDetail?.partner_name.split(' ');
    if (arrWords) {
      return _.get(arrWords, arrWords.length - 1);
    }
    return '';
  }, [bookingLineDetail]);

  const lastName = useMemo(() => {
    const arrWords = bookingLineDetail?.partner_name.split(' ');
    return arrWords?.slice(0, arrWords.length - 1).join(' ');
  }, [bookingLineDetail]);

  const Logo = () => (
    <Svg width="71" height="57" viewBox="0 0 71 57" fill="none">
      <Path
        d="M27.4297 10.9824C29.3562 10.9824 30.918 9.39243 30.918 7.43109C30.918 5.46974 29.3562 3.87976 27.4297 3.87976C25.5032 3.87976 23.9414 5.46974 23.9414 7.43109C23.9414 9.39243 25.5032 10.9824 27.4297 10.9824Z"
        fill="#2E3E54"
      />
      <Path
        d="M32.0671 41.665V19.8676C32.0671 14.4051 29.1785 13.5126 29.1785 13.5126H22.3688C22.3688 13.5126 25.2574 14.3525 25.2574 19.8676V41.665C25.2574 47.1275 28.146 48.02 28.146 48.02H34.9557C34.9557 48.02 32.0671 47.1801 32.0671 41.665Z"
        fill="#2E3E54"
      />
      <Path
        d="M56.7265 13.5125H51.6014V34.97C48.406 24.9959 43.1071 22.4329 43.1071 16.8734C43.1071 14.2473 44.3449 13.5114 44.3449 13.5114H36.2974V48.0211H37.226V19.9201C40.3211 30.3194 49.9168 36.8859 49.9168 44.7116C49.9168 47.1275 48.7817 48.0211 48.7817 48.0211H56.7265V13.5125Z"
        fill="#2E3E54"
      />
      <Path
        d="M23.7174 41.4252C23.7174 30.8553 16.8669 29.8775 12.597 30.2385C12.6915 30.117 12.7685 30.0177 12.779 30.0014L19.9877 19.8205C21.8321 17.2154 21.0283 15.5893 20.9933 15.5215C20.9863 15.5075 20.977 15.4946 20.9653 15.483L18.3509 12.7552C18.2937 12.6956 18.2039 12.6886 18.1385 12.7377C18.0732 12.7868 18.0534 12.8779 18.0907 12.9503C18.0977 12.9655 18.8409 14.4538 17.1084 16.9L10.1657 26.7035C10.1552 23.623 10.1552 19.845 10.1552 19.7212C10.1552 14.2821 7.27822 13.3931 7.27822 13.3931H0.5C0.5 13.3931 3.37578 14.2295 3.37578 19.7212V41.4252C3.37578 46.8643 6.25274 47.7533 6.25274 47.7533H13.0333C13.0333 47.7533 10.1563 46.9169 10.1563 41.4252V32.1158C12.1595 31.8541 16.9369 32.1684 16.9369 41.4252C16.9369 46.8643 19.8127 47.7533 19.8127 47.7533H26.5932C26.5932 47.7533 23.7163 46.9169 23.7163 41.4252H23.7174Z"
        fill="#2E3E54"
      />
      <Path
        d="M50.4709 0.5C44.3157 0.5 38.8103 2.99995 35.1365 6.93328C38.5256 3.8796 43.3392 2.18338 48.5436 2.60743C57.8348 3.36326 64.9747 10.6715 64.9747 18.9692V53.4043L30.4431 56.3014V56.5H70.4999V18.5312C70.4988 8.57343 61.5319 0.5 50.4709 0.5Z"
        fill="#2E3E54"
      />
    </Svg>
  );
  const ListItem = ({ children }: any) => {
    return (
      <View style={{ ...styles.flex, gap: '4px', width: '98%' }}>
        <View style={{ height: '100%' }}>
          <Text>{'\u2022'}</Text>
        </View>
        <Text style={styles.text}>{children}</Text>
      </View>
    );
  };

  const HotelInfo = () => (
    <View style={styles.headerContainer}>
      <View style={styles.headerLogo}>
        <Logo />
      </View>
      <View style={{ ...styles.center, marginTop: 12 }}>
        <Text style={styles.textBold}>GUEST REGISTRATION FORM</Text>
      </View>
      <View style={styles.center}>
        <Text style={styles.textSmallBold}>Folio number: {bookingLineDetail?.booking_id}</Text>
      </View>
    </View>
  );

  const TableInfo = () => (
    <View style={styles.tableContainer}>
      <View style={styles.tableRow}>
        <View style={{ ...styles.tableCell, width: '34%' }}>
          <Text style={styles.text}>Last name: {lastName}</Text>
        </View>
        <View style={{ ...styles.tableCell, width: '34%' }}>
          <Text style={styles.text}>First name: {firstName}</Text>
        </View>
        <View style={{ ...styles.tableCell, width: '34%' }}>
          <Text style={styles.text}>
            Nationality: {bookingLineDetail?.customers[0]?.country?.name}
          </Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={{ ...styles.tableCell, width: '50%' }}>
          <Text style={styles.text}>Room type: {bookingLineDetail?.room_type_name}</Text>
        </View>
        <View style={{ ...styles.tableCell, width: '50%' }}>
          <Text style={styles.text}>Room number: {bookingLineDetail?.room_name}</Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={{ ...styles.tableCell, width: '40%' }}>
          <Text style={styles.text}>
            Date of Birth:{' '}
            {bookingLineDetail?.customers[0]?.dob
              ? dayjs(bookingLineDetail?.customers[0]?.dob).format('DD/MM/YYYY')
              : ''}
          </Text>
        </View>
        <View style={{ ...styles.tableCell, width: '60%', columnGap: '20px' }}>
          <Text style={styles.text}>Sex</Text>

          <View style={styles.flex}>
            <View style={styles.square}>
              {bookingLineDetail?.customers[0]?.gender === 'male' && (
                <Text style={styles.text}>X</Text>
              )}
            </View>
            <Text style={styles.text}>Male</Text>
          </View>

          <View style={styles.flex}>
            <View style={styles.square}>
              {bookingLineDetail?.customers[0]?.gender === 'female' && (
                <Text style={styles.text}>X</Text>
              )}
            </View>
            <Text style={styles.text}>Female</Text>
          </View>
        </View>
      </View>

      {dataChange.isShowRoomRate && (
        <View style={styles.tableRow}>
          <View
            style={{
              ...styles.tableCell,
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '4px',
              width: '100%'
            }}
          >
            <Text style={styles.text}>
              Room rate: {formatCurrency(bookingLineDetail?.subtotal_price)}
            </Text>
            <Text style={styles.text}>This rate is inclusive of 8% VAT and 5% service charge</Text>
          </View>
        </View>
      )}

      <View style={styles.tableRow}>
        <View style={{ ...styles.tableCell, width: '50%' }}>
          <Text style={styles.text}>
            Document Number: {bookingLineDetail?.partner_identification}
          </Text>
        </View>
        <View style={{ ...styles.tableCell, width: '50%' }}>
          <Text style={styles.text}>Expiry Date:</Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={{ ...styles.tableCell, width: '50%' }}>
          <Text style={styles.text}>Email address: {dataChange?.email}</Text>
        </View>
        <View style={{ ...styles.tableCell, width: '50%' }}>
          <Text style={styles.text}>Phone number: {dataChange?.phone}</Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={{ ...styles.tableCell, width: '100%' }}>
          <Text style={styles.text}>
            Home address: {bookingLineDetail?.customers[0]?.contact_address_complete}
          </Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={{ ...styles.tableCell, width: '100%' }}>
          <Text style={styles.text}>Company: {dataChange?.company}</Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={{ ...styles.tableCell, width: '100%' }}>
          <Text style={styles.text}>
            Payment method: {dataChange?.paymentMethod || 'Room Only'}
          </Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={{ ...styles.tableCell, width: '100%' }}>
          <Text style={styles.text}>Remarks:</Text>
        </View>
      </View>

      <View style={{ ...styles.tableRow, borderRightWidth: 1 }}></View>
      <View style={{ ...styles.tableRow, borderRightWidth: 1 }}></View>

      <View style={styles.tableRow}>
        <View style={{ ...styles.tableCell, width: '50%' }}>
          <Text style={styles.text}>
            Date of Arrival:{' '}
            {bookingLineDetail?.check_in
              ? toLocalTime(bookingLineDetail?.check_in, 'DD/MM/YYYY')
              : ''}
          </Text>
        </View>
        <View style={{ ...styles.tableCell, width: '50%' }}>
          <Text style={styles.text}>
            Date of Departure:{' '}
            {bookingLineDetail?.check_in
              ? toLocalTime(bookingLineDetail?.check_out, 'DD/MM/YYYY')
              : ''}
          </Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={{ ...styles.tableCell, width: '50%', columnGap: '20px' }}>
          <Text style={styles.text}>VAT Invoice (manual input)</Text>
          <View style={styles.flex}>
            <View style={styles.square}>
              {dataChange?.isShowVAT && <Text style={styles.text}>X</Text>}
            </View>
            <Text style={styles.text}>Yes</Text>
          </View>
          <View style={styles.flex}>
            <View style={styles.square}>
              {!dataChange?.isShowVAT && <Text style={styles.text}>X</Text>}
            </View>
            <Text style={styles.text}>No</Text>
          </View>
        </View>
        <View
          style={{
            ...styles.tableCell,
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '4px',
            width: '50%'
          }}
        >
          <Text style={styles.text}>Number of Guest: {bookingLineDetail?.customers.length}</Text>
          <Text style={styles.text}>
            {!_.isEmpty(bookingLineDetail?.breakfast_dates)
              ? 'Breakfast Included'
              : 'Breakfast Is Not Included'}
          </Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View
          style={{
            ...styles.tableCell,
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '4px',
            width: '100%'
          }}
        >
          <ListItem>
            All guests must be registered, and an additional charge will apply for each extra
            person.
          </ListItem>
          <ListItem>Check-in time is from 2:00 PM, and check-out time is before 12:00 PM.</ListItem>
          <ListItem>
            Visiting hours are from 7:00 AM to 10:00 PM. Visitors must register at the Front Desk
            with a valid passport or ID card. Failure to comply with hotel regulations may result in
            declined check-in.
          </ListItem>
          <ListItem>
            Upon arrival, one key will be issued. Additional keys can be requested from our Front
            Desk Agent. A charge of 100,000 VND will be incurred for any lost key.
          </ListItem>
          <ListItem>
            Safe deposit boxes are available free of charge in guest rooms or at the Front Desk for
            securing valuables. The hotel is not liable for any loss of money, jewelry, or other
            valuables within the property.
          </ListItem>
          <ListItem>
            Guests are responsible for any loss or damage to items listed in the guest&apos;s room
            during their stay.
          </ListItem>
          <ListItem>
            A security deposit is required upon check-in and will be returned during check-out only
            if the room is found to be in normal condition, and all guest payments are settled.
          </ListItem>
          <ListItem>
            Smoking and consuming smelly foods such as durian or jackfruit, ect… inside the room or
            hotel premises will result in a penalty charge of 3,000,000 VND.
          </ListItem>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View
          style={{
            ...styles.tableCell,
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '2px',
            width: '100%',
            paddingTop: '8px'
          }}
        >
          <Text style={styles.text}>
            I agree to pay an additional VND ________________________per night for
            ____________________
          </Text>
          <Text style={styles.text}>
            Total VND_____________________ for_______ nights. Initial: ____________________
          </Text>
        </View>
      </View>

      <View style={{ ...styles.tableRow, minHeight: 88 }}>
        <View
          style={{
            ...styles.tableCell,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '50%'
          }}
        >
          <Text style={styles.text}>Guest Signature</Text>
          {signatureGuest && <Image style={styles.image} src={signatureGuest} />}
        </View>
        <View
          style={{
            ...styles.tableCell,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '50%'
          }}
        >
          <Text style={styles.text}>Front Desk Agent</Text>
          {signatureStaff && <Image style={styles.image} src={signatureStaff} />}
        </View>
      </View>
    </View>
  );

  return (
    <Document title="Đơn nhận phòng" language="vi">
      <Page wrap={false} size="A4" style={styles.page}>
        <HotelInfo />
        <TableInfo />
      </Page>
    </Document>
  );
}
