import React, { useMemo } from 'react';
import {
  Text,
  View,
  Page,
  Document,
  StyleSheet,
  Svg,
  Path,
  Font,
  Image
} from '@react-pdf/renderer';

import InvoiceItemsTable from './invoice-table';

import { BookingLine } from 'services/api/type/booking.type';
import { BranchesType } from 'services/api/type/branch.type';

// Register font
Font.register({
  family: 'Inter',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf',
      fontWeight: 100
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf',
      fontWeight: 200
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf',
      fontWeight: 300
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
      fontWeight: 400
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf',
      fontWeight: 500
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf',
      fontWeight: 600
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf',
      fontWeight: 700
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf',
      fontWeight: 800
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf',
      fontWeight: 900
    }
  ]
});

const styles = StyleSheet.create({
  page: {
    fontSize: 12,
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: 1.5,
    flexDirection: 'column'
  },

  spaceBetween: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#000'
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px'
  },

  image: {
    width: 60,
    height: 50,
    marginTop: 10,
    marginLeft: 20
  },

  titleContainer: {
    flexDirection: 'row',
    marginTop: 4,
    paddingBottom: 12,
    borderBottom: '1px solid #000',
    marginBottom: 8
  },
  billContainer: {
    flexDirection: 'column',
    paddingBottom: 8,
    borderBottom: '1px solid #000',
    marginBottom: 8
  },
  billRow: {
    flexDirection: 'row',
    columnGap: '108px'
  },
  billCol: {
    flexDirection: 'column',
    gap: '8px',
    justifyContent: 'flex-start'
  },
  billColItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: '12px'
  },
  billRowItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    columnGap: '12px'
  },

  signatureContainer: {
    flexDirection: 'row',
    marginTop: 28,
    marginRight: 28,
    justifyContent: 'flex-end',
    gap: 32
  },
  signatureItem: {
    flexDirection: 'column',
    rowGap: '8px',
    width: 100
  },

  logo: { width: 90 },
  text: { fontSize: 11, fontFamily: 'Inter' },
  textExSmallBold: { fontSize: 11, fontWeight: 600, fontFamily: 'Inter' },
  textBold: { fontSize: 14, fontWeight: 600, fontFamily: 'Inter' },
  textSmallBold: { fontSize: 12, fontWeight: 600, fontFamily: 'Inter' }
});

interface Props {
  bookingLineDetail: BookingLine | undefined;
  totalPayment: number;
  totalSubAmount: number;
  totalPrice: number;
  totalNLTE: string;
  totalFinalService: number;
  totalFinalServiceFee: number;
  totalFinalTax: number;
  bookingLineItems: any[];
  dataChange: any;
  signatureStaff: string;
  signatureGuest: string;
  currentBranch: BranchesType | undefined;
}

export default function BillGroupPDF({
  bookingLineDetail,
  totalPayment,
  totalSubAmount,
  totalPrice,
  totalNLTE,
  totalFinalService,
  totalFinalServiceFee,
  totalFinalTax,
  bookingLineItems,
  dataChange,
  signatureStaff,
  signatureGuest,
  currentBranch
}: Props) {
  const phone = useMemo(() => {
    const partner_id = bookingLineDetail?.partner_id;
    const customer = bookingLineDetail?.customers.find(
      customer => customer.partner_id === partner_id
    );
    return customer?.phone || '';
  }, [bookingLineDetail]);

  const Logo = () => (
    <Svg width="71" height="57" viewBox="0 0 71 57" fill="none">
      <Path
        d="M27.4297 10.9824C29.3562 10.9824 30.918 9.39243 30.918 7.43109C30.918 5.46974 29.3562 3.87976 27.4297 3.87976C25.5032 3.87976 23.9414 5.46974 23.9414 7.43109C23.9414 9.39243 25.5032 10.9824 27.4297 10.9824Z"
        fill="#2E3E54"
      />
      <Path
        d="M32.0671 41.665V19.8676C32.0671 14.4051 29.1785 13.5126 29.1785 13.5126H22.3688C22.3688 13.5126 25.2574 14.3525 25.2574 19.8676V41.665C25.2574 47.1275 28.146 48.02 28.146 48.02H34.9557C34.9557 48.02 32.0671 47.1801 32.0671 41.665Z"
        fill="#2E3E54"
      />
      <Path
        d="M56.7265 13.5125H51.6014V34.97C48.406 24.9959 43.1071 22.4329 43.1071 16.8734C43.1071 14.2473 44.3449 13.5114 44.3449 13.5114H36.2974V48.0211H37.226V19.9201C40.3211 30.3194 49.9168 36.8859 49.9168 44.7116C49.9168 47.1275 48.7817 48.0211 48.7817 48.0211H56.7265V13.5125Z"
        fill="#2E3E54"
      />
      <Path
        d="M23.7174 41.4252C23.7174 30.8553 16.8669 29.8775 12.597 30.2385C12.6915 30.117 12.7685 30.0177 12.779 30.0014L19.9877 19.8205C21.8321 17.2154 21.0283 15.5893 20.9933 15.5215C20.9863 15.5075 20.977 15.4946 20.9653 15.483L18.3509 12.7552C18.2937 12.6956 18.2039 12.6886 18.1385 12.7377C18.0732 12.7868 18.0534 12.8779 18.0907 12.9503C18.0977 12.9655 18.8409 14.4538 17.1084 16.9L10.1657 26.7035C10.1552 23.623 10.1552 19.845 10.1552 19.7212C10.1552 14.2821 7.27822 13.3931 7.27822 13.3931H0.5C0.5 13.3931 3.37578 14.2295 3.37578 19.7212V41.4252C3.37578 46.8643 6.25274 47.7533 6.25274 47.7533H13.0333C13.0333 47.7533 10.1563 46.9169 10.1563 41.4252V32.1158C12.1595 31.8541 16.9369 32.1684 16.9369 41.4252C16.9369 46.8643 19.8127 47.7533 19.8127 47.7533H26.5932C26.5932 47.7533 23.7163 46.9169 23.7163 41.4252H23.7174Z"
        fill="#2E3E54"
      />
      <Path
        d="M50.4709 0.5C44.3157 0.5 38.8103 2.99995 35.1365 6.93328C38.5256 3.8796 43.3392 2.18338 48.5436 2.60743C57.8348 3.36326 64.9747 10.6715 64.9747 18.9692V53.4043L30.4431 56.3014V56.5H70.4999V18.5312C70.4988 8.57343 61.5319 0.5 50.4709 0.5Z"
        fill="#2E3E54"
      />
    </Svg>
  );

  const HotelInfo = () => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <View>
          <Text style={{ ...styles.textBold, textTransform: 'uppercase' }}>
            {currentBranch?.name}
          </Text>
          <Text style={styles.text}>Address: {currentBranch?.address}</Text>
          <Text style={styles.text}>
            {currentBranch?.phone_contact ? `Tel: ${currentBranch?.phone_contact}` : ''}
          </Text>
        </View>
        <Logo />
      </View>
    </View>
  );

  const BillInfo = () => (
    <View style={styles.billContainer}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 8,
          gap: '12px'
        }}
      >
        <Text style={styles.textBold}>
          Hóa đơn đoàn (Group invoice) #{bookingLineDetail?.sale_order_name}
        </Text>
        <Text style={styles.textBold}>
          (Số hóa đơn (Invoice number) {bookingLineDetail?.sale_order_name})
        </Text>
      </View>

      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 8,
          gap: 20
        }}
      >
        <View style={{ ...styles.billRowItem, width: '50%' }}>
          <Text style={styles.text}>Tên khách (Name of guest):</Text>
          <Text style={styles.text}>
            {dataChange?.partnerName || bookingLineDetail?.partner_name}
          </Text>
        </View>
        <View style={{ ...styles.billRowItem, width: '20%' }}>
          <Text style={styles.text}>Di động (Mobile):</Text>
          <Text style={styles.text}>{phone}</Text>
        </View>
        <View style={{ ...styles.billRowItem, width: '30%' }}>
          <Text style={styles.text}>Booking ID:</Text>
          <Text style={styles.text}>{bookingLineDetail?.booking_id}</Text>
        </View>
      </View>

      <View style={styles.billRowItem}>
        <Text style={styles.text}>Công ty (Company):</Text>
        <Text style={styles.text}>{dataChange?.company}</Text>
      </View>

      {/* <View style={{ ...styles.billRow, marginTop: "8px" }}>
        <View style={{ ...styles.billCol, width: "40%" }}>
          <View style={styles.billColItem}>
            <Text style={styles.text}>Mã số thuế (Tax Code):</Text>
            <Text style={styles.textExSmallBold}>{dataChange?.taxCode}</Text>
          </View>
        </View>
        <View style={{ ...styles.billCol, width: "60%" }}>
          <View style={styles.billColItem}>
            <Text style={{ ...styles.text, width: "65%" }}>
              Ngày đến (Arrival):
            </Text>
            <Text style={{ ...styles.textExSmallBold, width: "35%" }}>
              {bookingLineDetail?.check_in
                ? toLocalTime(bookingLineDetail?.check_in, "DD/MM/YYYY")
                : ""}
            </Text>
          </View>
          <View style={styles.billColItem}>
            <Text style={{ ...styles.text, width: "65%" }}>
              Ngày đi (Departure):
            </Text>
            <Text style={{ ...styles.textExSmallBold, width: "35%" }}>
              {bookingLineDetail?.check_out
                ? toLocalTime(bookingLineDetail?.check_out, "DD/MM/YYYY")
                : ""}
            </Text>
          </View>
        </View>
      </View> */}
    </View>
  );

  const Signature = () => (
    <View style={styles.signatureContainer}>
      <View style={styles.signatureItem}>
        <Text
          style={{
            ...styles.text,
            paddingBottom: 4,
            paddingHorizontal: 8,
            borderBottom: '1px solid #000',
            textAlign: 'center'
          }}
        >
          Thu ngân ký (Cashier)
        </Text>
        {signatureStaff && <Image style={styles.image} src={signatureStaff} />}
      </View>
      <View style={styles.signatureItem}>
        <Text
          style={{
            ...styles.text,
            paddingBottom: 4,
            paddingHorizontal: 8,
            borderBottom: '1px solid #000',
            textAlign: 'center'
          }}
        >
          Khách ký (Guest)
        </Text>
        {signatureGuest && <Image style={styles.image} src={signatureGuest} />}
      </View>
    </View>
  );

  return (
    <Document title="Hóa đơn" language="vi">
      <Page wrap size="A4" orientation="landscape" style={styles.page}>
        <HotelInfo />
        <BillInfo />

        <InvoiceItemsTable
          totalPayment={totalPayment}
          totalSubAmount={totalSubAmount}
          totalPrice={totalPrice}
          totalNLTE={totalNLTE}
          totalFinalService={totalFinalService}
          totalFinalServiceFee={totalFinalServiceFee}
          totalFinalTax={totalFinalTax}
          bookingLineItems={bookingLineItems}
        />

        <Signature />
      </Page>
    </Document>
  );
}
