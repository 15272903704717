import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import {
  Button,
  Card,
  Col,
  Form,
  FormProps,
  Input,
  Modal,
  Radio,
  Row,
  Spin,
  Tabs,
  Upload,
  UploadProps,
  notification
} from 'antd';
import {
  IdcardOutlined,
  PlusOutlined,
  DeleteFilled,
  CameraOutlined,
  LoadingOutlined,
  UndoOutlined,
  RedoOutlined
} from '@ant-design/icons';
import imageCompression from 'browser-image-compression';

import { useGetCustomerById } from 'hooks/useGetCustomerById';

import { ReactComponent as IconMale } from 'assets/images/male.svg';
import { ReactComponent as IconFemale } from 'assets/images/female.svg';
import { ReactComponent as IconCardScan } from 'assets/images/id-card-scan.svg';

import { addOrUpdateCustomers } from 'services/api/module/customer.api';
import { SEX } from 'constants/form';
import { getBase64, rotateImage } from 'utils';
import { CustomerImageOCRType } from 'services/api/type/customer.type';
import API from 'services/api';
import QUERY_KEYS from 'services/api/queryKeys';
import { queryClient } from 'index';
import 'styles/customer-profile.scss';
import { logEvent } from 'services/tracking/gaTracking';

export const LIMIT_SIZE_FILE = 2000000; // 2MB
const LIMIT_PHOTO = 7;
const TAB_SCAN_CCCD = 'cccd';
const TAB_SCAN_PASSPORT = 'passport';

function CustomerProfile() {
  const [form] = Form.useForm();
  const { customerId } = useParams();
  const attachments = Form.useWatch('attachments', form);

  const [photoUrls, setPhotoUrls] = useState<string[]>([]);

  const [fileScan, setFileScan] = useState<any>();
  const [photoUrlScan, setPhotoUrlScan] = useState<string>('');
  const [activeKey, setActiveKey] = useState<string>(TAB_SCAN_CCCD);
  const [isShowScanOCR, setIsShowScanOCR] = useState<boolean>(false);

  const [rotation, setRotation] = useState(0);
  const [loadingProcessImage, setLoadingProcessImage] = useState<boolean>(false);

  const { data: customerInfo, isFetching } = useGetCustomerById(Number(customerId));

  const { mutateAsync: createOrUpdateCustomer, isPending } = useMutation({
    mutationFn: async (values: any) => {
      const data = {
        partner_id: customerId,
        name: values.name,
        gender: values.gender,
        identification: values.identification,
        remove_images: values.remove_images,
        images: values.attachments
          ? await Promise.all(
              values.attachments
                .filter((photo: any) => _.isNil(photo.id))
                .map(async (photo: any) => {
                  const compressedFile = await imageCompression(photo.originFileObj, {
                    maxSizeMB: 2,
                    maxWidthOrHeight: 1280
                  });
                  return getBase64(compressedFile);
                })
            )
          : undefined
      };
      const customers = await addOrUpdateCustomers([data]);
      return customers;
    }
  });

  const { mutateAsync: mutateImageOCR, isPending: isPendingOCR } = useMutation({
    mutationFn: (values: CustomerImageOCRType) => API.customer.getDataCustomerOCR(values)
  });

  useEffect(() => {
    if (!_.isEmpty(customerInfo)) {
      setPhotoUrls(customerInfo.attachments?.map(item => item.url) || []);
      form.setFieldsValue({
        name: customerInfo.name || '',
        identification: customerInfo.identification || '',
        gender: customerInfo.gender || SEX.MALE,
        attachments: customerInfo?.attachments || [],
        remove_images: []
      });
    }
  }, [customerInfo, form]);

  useEffect(() => {
    let result = [];

    if (!_.isEmpty(attachments)) {
      result = attachments.map((item: any) => {
        if (item.id) {
          return item.url;
        }
        return URL.createObjectURL(item.originFileObj);
      });
      setPhotoUrls(result);
    }
  }, [attachments]);

  useEffect(() => {
    if (!isShowScanOCR) {
      setPhotoUrlScan('');
      setFileScan(null);
      setActiveKey(TAB_SCAN_CCCD);
      reset();
    }
  }, [isShowScanOCR]);

  const handleDeletePhoto = (index: number) => {
    const newPhotosUrls = [...photoUrls];
    newPhotosUrls.splice(index, 1);

    setPhotoUrls(newPhotosUrls);

    const photos = _.cloneDeep(form.getFieldValue('attachments'));

    const deletedPhoto = photos.splice(index, 1);
    if (deletedPhoto[0]?.id) {
      const removeImages = _.cloneDeep(form.getFieldValue('remove_images')) || [];
      removeImages.push(deletedPhoto[0]?.id);
      form.setFieldValue('remove_images', removeImages);
    }

    form.setFieldValue('attachments', photos);
  };

  const handleChangeTab = (activeKey: string) => {
    reset();
    setActiveKey(activeKey);
  };

  const handleDeletePhotoScan = () => {
    reset();
  };

  const reset = () => {
    setPhotoUrlScan('');
    setFileScan(null);
    setRotation(0);
  };

  const handleSaveScanResult = async () => {
    try {
      setLoadingProcessImage(true);
      const rotatedBlob: any = await rotateImage(fileScan, rotation);
      const fileRotateScan = new File([rotatedBlob], fileScan.name, {
        type: fileScan.type
      });
      const compressedFile = await imageCompression(fileRotateScan, {
        maxSizeMB: 2,
        maxWidthOrHeight: 1280
      });
      const imageBase64 = await getBase64(compressedFile);
      await mutateImageOCR({
        partner_id: Number(customerId),
        identification_card: activeKey,
        images: String(imageBase64)
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_CUSTOMER_BY_ID, Number(customerId)]
      });

      setIsShowScanOCR(false);
      reset();
    } catch (err: any) {
      console.log(err);
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    } finally {
      setLoadingProcessImage(false);
    }
  };

  const handleSave = () => {
    form.submit();
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    try {
      await createOrUpdateCustomer(values);
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const handleRotateLeft = async () => {
    const newRotation = (rotation - 90) % 360;
    setRotation(newRotation);
    const rotatedBlob: any = await rotateImage(fileScan, newRotation);
    setPhotoUrlScan(URL.createObjectURL(rotatedBlob));
  };

  const handleRotateRight = async () => {
    const newRotation = (rotation + 90) % 360;
    const rotatedBlob: any = await rotateImage(fileScan, newRotation);
    setRotation(newRotation);
    setPhotoUrlScan(URL.createObjectURL(rotatedBlob));
  };

  const props: UploadProps = {
    accept: 'image/png, image/jpeg, image/jpg, image/bmp',
    name: 'file',
    multiple: true,
    // onChange({ fileList }) {
    //   const urls = fileList.map((file) => {
    //     return URL.createObjectURL(file.originFileObj as any);
    //   });
    //   setPhotoUrls(urls);
    // },
    beforeUpload: file => {
      const isPNG = file.type === 'image/png';
      const isBMP = file.type === 'image/bmp';
      const isJPG = ['image/jpg', 'image/jpeg'].includes(file.type);

      if (!(isPNG || isJPG || isBMP)) {
        notification.error({
          message: `${file.name} không phải định dạng jpg/jpeg/png/bmp`
        });
      }
      logEvent({
        category: 'customer_profile',
        action: 'customer_profile.upload_picture',
        label: 'Customer Upload Photo',
        value: 1
      });
      return false;
    },
    showUploadList: false,
    disabled: attachments?.length >= LIMIT_PHOTO
  };

  const ScanContent = useMemo(() => {
    const propScan: UploadProps = {
      accept: 'image/png, image/jpeg, image/jpg, image/bmp',
      name: 'file',
      multiple: false,
      async onChange({ file }) {
        setFileScan(file);
        // const imageUrl = await readFile(file);
        const rotatedBlob: any = await rotateImage(file, rotation);
        setPhotoUrlScan(URL.createObjectURL(rotatedBlob));
      },
      beforeUpload: file => {
        const isPNG = file.type === 'image/png';
        const isBMP = file.type === 'image/bmp';
        const isJPG = ['image/jpg', 'image/jpeg'].includes(file.type);

        if (!(isPNG || isJPG || isBMP)) {
          notification.error({
            message: `${file.name} không phải định dạng jpg/jpeg/png/bmp`
          });
        }
        logEvent({
          category: 'customer_profile',
          action: 'customer_profile.scan_picture',
          label: 'Customer Scam Photo',
          value: 1
        });
        return false;
      },
      showUploadList: false
    };

    if (photoUrlScan) {
      return (
        <div className="preview-scan-img">
          <img
            src={photoUrlScan}
            alt="ocr"
            // style={{ transform: `rotate(${rotation}deg)` }}
          />

          {photoUrlScan && (
            <>
              <Button
                icon={<DeleteFilled />}
                className="btn-action"
                onClick={handleDeletePhotoScan}
              />
              <Button icon={<UndoOutlined />} className="btn-left" onClick={handleRotateLeft} />
              <Button icon={<RedoOutlined />} className="btn-right" onClick={handleRotateRight} />
            </>
          )}
        </div>
      );
    }
    return (
      <Upload.Dragger {...propScan} maxCount={LIMIT_PHOTO}>
        <p className="ant-upload-drag-icon m-0">
          <CameraOutlined />
        </p>
        <p className="ant-upload-text">{activeKey === TAB_SCAN_CCCD ? 'Mặt trước' : 'Hộ chiếu'}</p>
      </Upload.Dragger>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey, photoUrlScan, rotation]);

  const SCAN_TABS = [
    {
      label: `CMND/CCCD`,
      key: TAB_SCAN_CCCD,
      children: <div>{ScanContent}</div>
    },
    {
      label: `Hộ chiếu`,
      key: TAB_SCAN_PASSPORT,
      children: <div>{ScanContent}</div>
    }
  ];

  return (
    <div className="pms-qr-code">
      <Card title="Thông tin khách" style={{ width: '100%' }}>
        <Form
          form={form}
          name="qr-code-form"
          layout="vertical"
          style={{ width: '100%' }}
          initialValues={{
            name: '',
            identification: '',
            gender: SEX.MALE,
            attachments: [],
            remove_images: []
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={[8, 16]}>
            <Col span={16}>
              <Form.Item name="name" className="m-0">
                <Input placeholder="Tên khách" addonBefore={<IconMale />} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="gender" className="m-0">
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value={SEX.MALE}>
                    <IconMale />
                  </Radio.Button>
                  <Radio.Button value={SEX.FEMALE}>
                    <IconFemale />
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span={16}>
              <Form.Item name="identification" className="m-0">
                <Input
                  placeholder="CMND/Hộ chiếu"
                  addonBefore={<IdcardOutlined style={{ width: '20px' }} />}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Button
                icon={<IconCardScan />}
                className="ant-btn-secondary-outline flex items-center"
                onClick={() => setIsShowScanOCR(true)}
              >
                Quét
              </Button>
            </Col>
          </Row>

          <div className="pid-box">
            <Row gutter={[16, 16]}>
              {!_.isEmpty(photoUrls) &&
                photoUrls.map((item, index) => (
                  <Col span={12} key={index}>
                    <div className="preview-item" key={index}>
                      <img src={item} alt="thumbnail" />
                      <Button
                        icon={<DeleteFilled />}
                        className="btn-action"
                        onClick={() => handleDeletePhoto(index)}
                      />
                    </div>
                  </Col>
                ))}

              <Col span={12}>
                <Form.Item
                  name="attachments"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  className="m-0"
                >
                  <Upload.Dragger {...props} maxCount={LIMIT_PHOTO}>
                    <p className="ant-upload-drag-icon m-0">
                      <PlusOutlined /> <span>Thêm ảnh</span>
                    </p>
                    <p className="ant-upload-text">{attachments?.length}/7</p>
                  </Upload.Dragger>
                </Form.Item>
              </Col>
            </Row>
          </div>

          <Form.Item name="remove_images" style={{ display: 'none' }}>
            <Input />
          </Form.Item>
        </Form>
      </Card>
      <div className="box-action">
        <div style={{ padding: '0 24px' }}>
          <Button className="ant-btn-secondary w-full" onClick={handleSave} loading={isPending}>
            Lưu
          </Button>
        </div>
      </div>

      <Modal
        title="Quét thông tin khách"
        open={isShowScanOCR}
        okText="Lưu"
        cancelText="Đóng"
        className="modal-scan-ocr"
        onCancel={() => setIsShowScanOCR(false)}
        okButtonProps={{ className: 'ant-btn-secondary' }}
        onOk={handleSaveScanResult}
        confirmLoading={isPendingOCR || loadingProcessImage}
      >
        <Tabs activeKey={activeKey} onChange={handleChangeTab} centered items={SCAN_TABS} />
      </Modal>

      {isFetching && (
        <Spin
          fullscreen
          size="large"
          indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
        />
      )}
    </div>
  );
}

export default CustomerProfile;
