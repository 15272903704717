import { axiosInstance } from '../base.api';
import { paths } from '../paths';
import { TravelAgencyResponse } from '../type/travel-agency.type';

export const createTravelAgency = async (data: any): Promise<string> => {
  return axiosInstance.post(paths.travelAgencies(), data).then(response => response.data);
};

export const getTravelAgencies = async (values: any): Promise<TravelAgencyResponse[]> => {
  return axiosInstance
    .get(paths.travelAgencies(), {
      params: values
    })
    .then(response => response.data.data);
};

export const getTravelAgency = async (id: number): Promise<TravelAgencyResponse[]> => {
  return axiosInstance.get(paths.getTravelAgency(id)).then(response => response.data.data);
};
