import ExcelJS from 'exceljs';
import { ACTION_EXPORT_DATA } from 'constants/common';
import { MenuProps } from 'antd';
import * as FileSaver from 'file-saver';

// Logo image and Save Excel file
export const addImageAndSaveExcel = async (
  workbook: ExcelJS.Workbook,
  sheet: ExcelJS.Worksheet,
  logoBase64: string,
  fileName: string,
  fileType: string
) => {
  const imageId = workbook.addImage({
    base64: logoBase64,
    extension: 'png'
  });

  sheet.addImage(imageId, {
    tl: { col: 1, row: 1 },
    ext: { width: 71, height: 57 }
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const data = new Blob([buffer], { type: fileType });
  FileSaver.saveAs(data, fileName);
};

//
export const handleMenuClick = (menuKey: string, exportToCSV: () => void) => {
  switch (menuKey) {
    case ACTION_EXPORT_DATA.EXCEL:
      exportToCSV();
      break;
    default:
      break;
  }
};

// Add text for Menu items
export const generateMenuItems = (): MenuProps['items'] => {
  return [
    {
      key: ACTION_EXPORT_DATA.EXCEL,
      label: 'Excel'
    }
  ];
};

// Sheet header
export const addExcelSheetHeader = (sheet: ExcelJS.Worksheet, currentBranch: any) => {
  // Title
  sheet.mergeCells('A1:J1');
  sheet.getCell('A1').value = currentBranch?.name?.toUpperCase() || '';
  sheet.getCell('A1').font = { bold: true, size: 14 };
  sheet.getCell('A1').alignment = { horizontal: 'center' };

  // Address
  sheet.mergeCells('A2:J2');
  sheet.getCell('A2').value = `Địa chỉ: ${currentBranch?.address || ''}`;
  sheet.getCell('A2').font = { size: 10 };
  sheet.getCell('A2').alignment = { horizontal: 'center' };

  // Support
  sheet.mergeCells('A3:J3');
  sheet.getCell('A3').value = currentBranch?.phone_contact
    ? `ĐT: ${currentBranch.phone_contact}`
    : '';
  sheet.getCell('A3').font = { size: 10 };
  sheet.getCell('A3').alignment = { horizontal: 'center' };
};
