import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { Dayjs } from 'dayjs';
import _ from 'lodash';
import {
  Button,
  Card,
  Input,
  DatePicker,
  DatePickerProps,
  Select,
  Form,
  FormProps,
  Table,
  notification
} from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Labels } from 'services/api/type/labels.type';

import useModal from 'stores/useModal';
import useBookingStore from 'stores/useBooking';
import { useGetBooking } from 'hooks/useGetBooking';
import { useGetAllLabels } from 'hooks/useGetAllLabels';

import QUERY_KEYS from 'services/api/queryKeys';
import { RangeValueType } from 'services/api/type/common.type';
import { BOOKING_STATUS_OPTIONS } from 'constants/form';
import { PAGE_SIZE_BOOKING_LIST, actionCheckIn } from 'services/api/module/booking.api';
import {
  getBookingListColumns,
  ACTION_BOOKING,
  getBookingListColumnsExpand
} from 'constants/table';
import 'styles/booking-list.scss';

const disabledDate: DatePickerProps['disabledDate'] = (current, { from }) => {
  if (from) {
    return current.isBefore(from);
  }

  return false;
};

function BookingList() {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const isIpadMobile = useMediaQuery({
    query: '(max-width: 991px)'
  });
  const {
    setIsOpenCancelRoom,
    setIsOpenAddService,
    setIsOpenChangeRoom,
    setIsOpenChangeDate,
    setOpenBookingDetail,
    setBookingLineId,
    setIsOpenCancelAssignRoom,
    setIsOpenAssignRoom,
    setInfoConfirmModal
  } = useModal();
  const { setBookingLineId: setBookingLineIdBookingStore } = useBookingStore();
  const selectedBookingLineId = useRef<number>();

  const [selectedLabel, setSelectedLabel] = useState<string>('');
  const { data: listLabels } = useGetAllLabels(selectedLabel);

  const [filterOptions, setFilterOptions] = useState<any>({
    status: ''
  });

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_BOOKING_LIST);
  const { data, pagination, isFetching } = useGetBooking(filterOptions, page, pageSize);
  const { mutateAsync: mutateCheckIn } = useMutation({
    mutationFn: (bookingLineId: number) => actionCheckIn(bookingLineId)
  });

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_LIST]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    if (Array.isArray(objValue['label_name'])) {
      objValue['label_name'] = objValue['label_name'].join(',');
    }
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});

    const { rangeDate, ...restFilterOptions } = truthyValues;
    if (rangeDate) {
      const checkInFrom: Dayjs = rangeDate[0];
      const checkInTo: Dayjs = rangeDate[1];
      if (checkInFrom) {
        _.set(restFilterOptions, 'check_in_from', checkInFrom.format('YYYY-MM-DD HH:mm:ss'));
      }
      if (checkInTo) {
        _.set(restFilterOptions, 'check_in_to', checkInTo.format('YYYY-MM-DD HH:mm:ss'));
      }
    }

    if (page !== 1) {
      setPage(1);
    }
    setFilterOptions(restFilterOptions);
  };

  const onFinishFailed: FormProps['onFinishFailed'] = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const handleClickMenu = (menu: any) => {
    switch (menu.key) {
      case ACTION_BOOKING.DETAIL:
        setOpenBookingDetail(true);
        break;
      case ACTION_BOOKING.ADD_SERVICE:
        setIsOpenAddService(true);
        break;
      case ACTION_BOOKING.CANCEL_BOOKING:
        setIsOpenCancelRoom(true);
        break;
      case ACTION_BOOKING.CHANGE_ROOM:
        setIsOpenChangeRoom(true);
        break;
      case ACTION_BOOKING.CHANGE_DATE:
        setIsOpenChangeDate(true);
        break;
      case ACTION_BOOKING.GET_ROOM:
        setInfoConfirmModal(true, {
          title: 'Xác nhận nhận phòng',
          onOk: () => {
            setInfoConfirmModal(false);
            handleCheckIn();
          }
        });
        break;
      case ACTION_BOOKING.ASSIGN_ROOM:
        setIsOpenAssignRoom(true);
        break;
      case ACTION_BOOKING.CANCEL_ASSIGN_ROOM:
        setIsOpenCancelAssignRoom(true);
        break;
      default:
        notification.warning({
          message: 'Not yet implemented'
        });
        break;
    }
  };

  const handleCheckIn = async () => {
    if (selectedBookingLineId.current) {
      try {
        await mutateCheckIn(selectedBookingLineId.current);
        notification.success({
          message: 'Nhận phòng thành công'
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_LIST, page]
        });
        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEYS.KEY_ROOM,
            QUERY_KEYS.GET_BOOKING_DETAIL,
            selectedBookingLineId.current
          ]
        });
      } catch (err: any) {
        notification.error({
          message: err.error || 'Lỗi xảy ra'
        });
      }
    }
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleRangeDateChange = (value: RangeValueType | null) => {
    let startDate: Dayjs | null = _.get(value, '0', null);
    let endDate: Dayjs | null = _.get(value, '1', null);
    if (startDate) {
      startDate = startDate.set('hour', 0).set('minute', 0).set('second', 0);
    }
    if (endDate) {
      endDate = endDate.set('hour', 23).set('minute', 59).set('second', 59);
    }
    form.setFieldValue('rangeDate', [startDate, endDate]);
  };

  const columnBookingList = useMemo(() => {
    return getBookingListColumns(handleClickMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnBookingListExpand = useMemo(() => {
    return getBookingListColumnsExpand(handleClickMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLabelChange = (value: string) => {
    setSelectedLabel(value);
  };

  // eslint-disable-next-line
  const handleSearch = useCallback(
    _.debounce(async search => {
      setSelectedLabel(search);
    }, 500),
    []
  );

  return (
    <div className="pms-booking-list">
      <Card title="Tất cả" style={{ width: '100%' }}>
        <Form
          form={form}
          name="booking-filter-form"
          layout={isIpadMobile ? 'vertical' : 'inline'}
          initialValues={{
            booking_id: '',
            rangeDate: null,
            status: '',
            label_name: null,
            search: ''
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="pms-booking-list__filter">
            <Form.Item name="search" style={{ flex: 1 }}>
              <Input placeholder="Tìm theo tên, số điện thoại, số phòng hoặc loại phòng, công ty" />
            </Form.Item>

            <Form.Item className="labels-search" name="label_name">
              <Select
                value={selectedLabel}
                options={listLabels.map((label: Labels) => ({
                  value: label.name,
                  label: label.name,
                  color: label.color
                }))}
                placeholder="Nhóm"
                mode="multiple"
                onChange={onLabelChange}
                onSearch={handleSearch}
                onClear={() => setSelectedLabel('')}
                showSearch
                allowClear
                optionRender={option => {
                  return option.label ? (
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px 10px'
                      }}
                    >
                      <span
                        style={{
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: option.data.color || '#f0f0f0',
                          marginRight: '8px'
                        }}
                      />
                      {option.label}
                    </span>
                  ) : null;
                }}
              />
            </Form.Item>

            <Form.Item className="group-id" name="group_id">
              <Input placeholder="Mã đoàn" />
            </Form.Item>

            <Form.Item name="booking_id">
              <Input placeholder="Mã đặt phòng" />
            </Form.Item>

            <Form.Item name="rangeDate">
              <DatePicker.RangePicker
                className="w-full"
                placeholder={['Ngày giờ bắt đầu', 'Ngày giờ kết thúc']}
                disabledDate={disabledDate}
                format="YYYY-MM-DD HH:mm"
                onChange={handleRangeDateChange}
              />
            </Form.Item>

            <Form.Item name="status">
              <Select
                options={BOOKING_STATUS_OPTIONS}
                labelRender={option => <span>Trạng thái: {option.label}</span>}
              />
            </Form.Item>

            <Button htmlType="submit" className="ant-btn-secondary btn-submit">
              Tìm kiếm
            </Button>
          </div>
        </Form>

        <div className="pms-booking-list__table">
          <Table
            loading={isFetching}
            rowKey="booking_id"
            columns={columnBookingList}
            dataSource={data}
            onRow={record => {
              return {
                onClick: () => {
                  setBookingLineId(record.booking_line_id);
                  setBookingLineIdBookingStore(record.booking_line_id);
                  selectedBookingLineId.current = record.booking_line_id;
                  console.log('record.booking_line_id', record.booking_line_id);
                } // click row
              };
            }}
            pagination={{
              current: page,
              position: ['bottomCenter'],
              total: pagination.total,
              pageSize: pageSize,
              locale: { items_per_page: '/ trang' },
              showLessItems: true,
              showTotal: total => `${total} items`,
              onChange: (page: number) => handleChangePage(page),
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              }
            }}
            locale={{
              emptyText: <span className="empty-data">Không có dữ liệu</span>
            }}
            scroll={{ x: 500, y: 'calc(100vh - 400px)' }}
            expandable={{
              expandedRowRender: record => {
                return (
                  <Table
                    rowKey="booking_line_id"
                    columns={columnBookingListExpand}
                    showHeader={false}
                    dataSource={record.expandableRows}
                    pagination={false}
                    scroll={{ x: 500 }}
                    onRow={record => {
                      return {
                        onClick: () => {
                          setBookingLineId(record.booking_line_id);
                          setBookingLineIdBookingStore(record.booking_line_id);
                          selectedBookingLineId.current = record.booking_line_id;
                          console.log('record.booking_line_id', record.booking_line_id);
                        } // click row
                      };
                    }}
                  />
                );
              },
              rowExpandable: record => !_.isEmpty(record.expandableRows),
              columnWidth: 30,
              defaultExpandAllRows: true
            }}
          />
        </div>
      </Card>
    </div>
  );
}

export default BookingList;
