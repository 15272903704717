import { getEndOfTommorow, getStartOfRightDay } from 'utils';
import CustomTable from './CustomTable';
import { columnSubGroupsTab } from 'constants/table';
import { useGetGroupsBookingDetails } from 'hooks/useGetGroupBookings';

interface SubGroupsProps {
  isEnable: boolean;
  dateToQuery: any;
  roomTypeToQuery: string;
}

export default function SubGroupsTab({ isEnable, dateToQuery, roomTypeToQuery }: SubGroupsProps) {
  const { data: listGroups, isLoading } = useGetGroupsBookingDetails(
    {
      check_in: getStartOfRightDay(dateToQuery).format('YYYY-MM-DD'),
      check_out: getEndOfTommorow(dateToQuery).format('YYYY-MM-DD'),
      short_code: roomTypeToQuery
    },
    isEnable
  );

  return (
    <CustomTable
      rowKey="ref_code"
      loading={isLoading}
      columns={columnSubGroupsTab}
      dataSource={listGroups || []}
    />
  );
}
