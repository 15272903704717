import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';

export const useGetCustomers = (search?: string, companyType = 'person', enabled = true) => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_CUSTOMERS, companyType, search],
    queryFn: () =>
      API.room.getCustomers({
        search,
        company_type: companyType
      }),
    enabled
  });

  return {
    data,
    status,
    isLoading,
    isFetching
  };
};
