import { useEffect } from 'react';

import { Modal, Form, Input, Row, Col, Space, FormProps, notification, Select } from 'antd';
import { ReactComponent as IconClose } from 'assets/images/close.svg';

import API from 'services/api';
import QUERY_KEYS from 'services/api/queryKeys';
import { queryClient } from 'index';
import { useMutation } from '@tanstack/react-query';

import { MESSAGE_CODE } from 'constants/validate';
import { EmailRegex } from 'constants/regex';

import { useGetCountries } from 'hooks/useGetCountries';
import { useGetAreas } from 'hooks/useGetAreas';

function CreateTravelAgency({ isOpen, onCancel }: { isOpen: boolean; onCancel: () => void }) {
  const [form] = Form.useForm();
  const countryIdForm = Form.useWatch('country_id', form);
  const { data: countries } = useGetCountries();
  const { data: areas } = useGetAreas(countryIdForm);

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
    }
  }, [form, isOpen]);

  const { mutateAsync: mutateCreateTravelAgency } = useMutation({
    mutationFn: (values: any) => API.ta.createTravelAgency(values)
  });

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    try {
      await mutateCreateTravelAgency(values);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_TRAVEL_AGENCIES] });
      notification.success({
        message: 'Lưu thành công'
      });
      onCancel();
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  const handleOk = () => {
    form.submit();
  };

  return (
    <Modal
      title="Tạo công ty du lịch mới"
      centered
      width={990}
      open={isOpen}
      onCancel={onCancel}
      cancelButtonProps={{
        style: {
          display: 'none'
        }
      }}
      onOk={handleOk}
      okButtonProps={{ className: 'ant-btn-secondary' }}
      okText="Lưu"
      closeIcon={<IconClose />}
      className="modal-add-travel-agency-info"
    >
      <Form
        form={form}
        name="travel-agency-info-form"
        layout="vertical"
        style={{ width: '100%' }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Space className="space-last-name w-full">
              <Form.Item
                label="Tên công ty"
                name="name"
                className="w-full"
                rules={[
                  {
                    required: true,
                    message: MESSAGE_CODE.REQUIRED_TRAVEL_AGENCY_NAME
                  }
                ]}
              >
                <Input className="w-full" />
              </Form.Item>
            </Space>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  pattern: EmailRegex,
                  message: MESSAGE_CODE.WRONG_FORMAT_EMAIL
                }
              ]}
            >
              <Input className="w-full" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Số điện thoại" name="phone">
              <Input className="w-full" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Mã số thuế" name="vat">
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Quốc gia" name="country_id">
              <Select
                showSearch
                filterOption={(input: string, option: any) => {
                  return option.name.toLowerCase().includes(input.toLowerCase().trim());
                }}
                options={countries || []}
                fieldNames={{ label: 'name', value: 'id' }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Khu vực" name="state_id">
              <Select
                showSearch
                options={areas}
                filterOption={(input: string, option: any) => {
                  return option.name.toLowerCase().includes(input.toLowerCase().trim());
                }}
                fieldNames={{ label: 'name', value: 'id' }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Địa chỉ" name="street">
              <Input className="w-full" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default CreateTravelAgency;
