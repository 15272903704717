import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Modal, Form, FormProps, notification, DatePicker } from 'antd';
import useModalStore from 'stores/useModal';
import useBookingStore from 'stores/useBooking';

import { ReactComponent as IconClose } from 'assets/images/close.svg';
import { BookingStatus } from 'services/api/type/booking.type';
import { updateBreakfast } from 'services/api/module/booking.api';
import { useMutation } from '@tanstack/react-query';
import { toUtcTime } from 'utils';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';

function SetBreakfastModal() {
  const [form] = Form.useForm();

  const { isOpenSetBreakfast, setIsOpenSetBreakfast } = useModalStore();
  const { bookingLineDetail } = useBookingStore();

  const { mutateAsync: mutateUpdateBreakfast, isPending } = useMutation({
    mutationFn: (params: any = []) =>
      updateBreakfast(Number(bookingLineDetail?.booking_line_id), params)
  });

  useEffect(() => {
    if (bookingLineDetail && isOpenSetBreakfast) {
      form.setFieldsValue({
        breakfast_dates: bookingLineDetail.breakfast_dates
          .filter(item => item.active)
          .map(item => dayjs.utc(item.date).local())
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingLineDetail, isOpenSetBreakfast]);

  const handleCloseSetBreakfast = () => {
    setIsOpenSetBreakfast(false);
    form.resetFields();
  };

  const handleSetBreakfast = () => {
    form.submit();
  };

  const startDate = useMemo(() => {
    const dateString = _.get(bookingLineDetail, 'check_in', '');
    const date = dayjs.utc(dateString).local().add(1, 'day');
    return date;
  }, [bookingLineDetail]);

  const endDate = useMemo(() => {
    if (bookingLineDetail) {
      const dateString = _.get(bookingLineDetail, `check_out`, '');
      return dayjs.utc(dateString).local();
    }
    return dayjs();
  }, [bookingLineDetail]);

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    if (bookingLineDetail) {
      console.log('zxczxc', values);
      try {
        await mutateUpdateBreakfast(
          values?.breakfast_dates?.map((item: any) => {
            return {
              date: toUtcTime(item.set('hour', 23).set('minute', 59), 'YYYY-MM-DD')
            };
          }) || []
        );

        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEYS.KEY_ROOM,
            QUERY_KEYS.GET_BOOKING_DETAIL,
            bookingLineDetail.booking_line_id
          ]
        });

        setIsOpenSetBreakfast(false);
      } catch (err: any) {
        notification.error({
          message: err.error || 'Có lỗi xảy ra'
        });
      }
    }
  };

  return (
    <Modal
      title="Ăn sáng"
      centered
      width={410}
      open={isOpenSetBreakfast}
      onCancel={handleCloseSetBreakfast}
      closeIcon={<IconClose />}
      okText="Lưu"
      cancelText="Đóng"
      onOk={handleSetBreakfast}
      okButtonProps={{ className: 'ant-btn-secondary' }}
      className="modal-set-breakfast"
      confirmLoading={isPending}
      destroyOnClose
    >
      <Form
        form={form}
        name="breakfast-calendar-form"
        layout="vertical"
        style={{ width: '100%' }}
        initialValues={{ breakfast_dates: [] }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <p>Lựa chọn ngày có ăn sáng cho phòng</p>

        <Form.Item label="Ngày ăn sáng" name="breakfast_dates">
          <DatePicker
            multiple
            minDate={startDate}
            maxDate={endDate}
            maxTagCount="responsive"
            format="DD/MM/YYYY"
            className="w-full"
            placeholder="Chọn ngày"
            disabled={
              ![BookingStatus.CHECK_IN, BookingStatus.CONFIRM].includes(
                bookingLineDetail?.status as BookingStatus
              )
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default SetBreakfastModal;
