import { useMemo, useState } from 'react';
import { Button, Card, Form, FormProps, Table, DatePicker } from 'antd';
import { useGetBreakfasts } from 'hooks/useGetBreakfasts';
import 'styles/breakfasts.scss';
import { columnBreakfasts } from 'constants/table';
import dayjs from 'dayjs';
import ExportBreakfastList from 'components/common/export-breakfast-list';
import { getEndOfToday, getStartOfToday } from 'utils';
import { nanoid } from 'nanoid';
import { BreakfastsType } from 'services/api/type/breakfasts.type';

function Breakfasts() {
  const [form] = Form.useForm();
  const [date, setDate] = useState<string | undefined>(dayjs().format('YYYY-MM-DD HH:mm:ss'));
  const { data, isFetching } = useGetBreakfasts(
    date ? dayjs(date).format('YYYY-MM-DD HH:mm:ss') : undefined
  );

  const dataSource = useMemo(() => {
    if (data) {
      const dataArray = Object.entries(data).map(([date, data]) => ({
        date,
        ...data
      }));

      const totalAdults = dataArray.reduce((sum, item) => sum + (item.adult || 0), 0);
      const totalChildren = dataArray.reduce((sum, item) => sum + (item.child || 0), 0);

      const averageRow = {
        booking_id:
          totalAdults || totalChildren ? (
            <span
              style={{
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%'
              }}
            >
              TOTAL
            </span>
          ) : (
            ''
          ),
        name: '',
        room_name: '',
        adult: totalAdults ? totalAdults : '',
        child: totalChildren ? totalChildren : '',
        arrival: '',
        departure: ''
      };

      return [...dataArray, averageRow];
    }
    return [];
  }, [data]);

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});
    setDate(truthyValues.date || undefined);
  };

  const transformData = (
    data: BreakfastsType[]
  ): {
    breakfastsData: BreakfastsType[];
    totalMembers: number;
  } => {
    let totalMembers = 0;
    data.forEach(item => {
      const { name } = item;
      if (name) {
        totalMembers += 1;
      }
    });
    return {
      breakfastsData: data,
      totalMembers
    };
  };

  const { breakfastsData, totalMembers } = transformData(data || []);
  const dataToExport: any = breakfastsData?.map(item => ({
    booking_id: item.booking_id,
    name: item.name,
    room_name: item.room_name,
    adult: item.adult || 0,
    child: item.child || 0,
    arrival: item.arrival,
    departure: item.departure
  }));

  return (
    <div className="pms-breakfast-list">
      <Card title="Danh sách ăn sáng" style={{ width: '100%' }}>
        <Form
          form={form}
          name="breakfast-filter-form"
          layout="inline"
          initialValues={{
            date: dayjs()
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="pms-breakfast-list__filter-wrapper">
            <div className="pms-breakfast-list__filter">
              <Form.Item name="date" className="m-0">
                <DatePicker />
              </Form.Item>

              <Button htmlType="submit" className="ant-btn-secondary btn-submit">
                Tìm kiếm
              </Button>
            </div>
            <ExportBreakfastList
              fileName={`breakfast-list-${getStartOfToday()}-${getEndOfToday()}-${nanoid()}`}
              dataToExport={dataToExport}
              totalMembers={totalMembers}
            />
          </div>
        </Form>

        <div className="pms-room-lock__table">
          <Table
            loading={isFetching}
            rowKey="id"
            columns={columnBreakfasts}
            dataSource={dataSource}
            locale={{
              emptyText: <span className="empty-data">No data available</span>
            }}
            scroll={{ x: 500, y: 'calc(100vh - 320px)' }}
          />
        </div>
      </Card>
    </div>
  );
}

export default Breakfasts;
