export const paths = {
  login() {
    return '/auth/login';
  },
  me() {
    return '/auth/me';
  },
  changePassword() {
    return '/users/change-password';
  },

  bookings(page?: number, limit?: number) {
    return `/bookings${page ? `?page=${page}&limit=${limit}` : ''}`;
  },
  allBookings() {
    return `/bookings`;
  },
  bookingDetail(id: number) {
    return `/booking-lines/${id}`;
  },
  updateInvoiceDetails(id: number) {
    return `/booking-lines/${id}/update-invoice-details`;
  },
  getAvailRoomType(startDate: string, endDate: string) {
    return `/room-types?start_date=${startDate}&end_date=${endDate}`;
  },
  actionCheckIn(id: number) {
    return `/booking-lines/${id}/checkin`;
  },
  assignCustomer() {
    return `/booking-lines/assign-customer`;
  },
  actionCheckOut(id: number) {
    return `/booking-lines/${id}/checkout`;
  },
  actionChangeDate(id: number) {
    return `/booking-lines/${id}/change-date`;
  },
  payments(id: number) {
    return `/booking-lines/${id}/payments`;
  },
  getCustomers() {
    return '/customers';
  },
  getCustomerById(customerId: number) {
    return `/customer-info/${customerId}`;
  },
  getDataCustomerOCR() {
    return '/customers-image-ocr';
  },
  getRooms() {
    return '/rooms';
  },
  getRoomStatus() {
    return '/room-status';
  },
  cleanRoom() {
    return '/room_clean';
  },
  assignRoom() {
    return '/booking-lines/assign-room';
  },
  changeRoomType() {
    return '/booking-lines/change-room-type';
  },
  exchangeRoom() {
    return '/booking-lines/exchange-room';
  },
  unassignRoom() {
    return '/booking-lines/unassign-room';
  },
  cancelBooking(bookingLineId: number) {
    return `/booking-lines/${bookingLineId}/cancel`;
  },
  updateExtraInfo(bookingLineId: number) {
    return `booking-lines/${bookingLineId}/update-extra-info`;
  },
  removeService(bookingLineId: number) {
    return `booking-lines/${bookingLineId}/remove-service`;
  },
  cancelPayment() {
    return `/cancel-payment`;
  },
  getTimeline() {
    return `/bookings/timeline`;
  },
  batchAction() {
    return `/booking-lines/batch_actions`;
  },

  getCountries() {
    return '/res/countries';
  },
  getAreas(countryId: number) {
    return `/res/states?country_id=${countryId}`;
  },
  addCustomer() {
    return '/customers';
  },
  sendEmail(bookingId: number) {
    return `/booking-confirm?booking_id=${bookingId}`;
  },
  getTracking(bookingLineId: number) {
    return `/tracking-logs?booking_line_id=${bookingLineId}`;
  },
  getServices() {
    return '/services';
  },
  getBreakfasts(date: string) {
    return `/breakfasts?date=${date}`;
  },
  addServices(bookingLineId: number) {
    return `/booking-lines/${bookingLineId}/add-service`;
  },
  changePrices(bookingLineId: number) {
    return `/booking-lines/${bookingLineId}/prices`;
  },

  getCleaningCalendar() {
    return `/booking-lines/clean-calendar`;
  },

  fixRoom() {
    return '/room-lock';
  },
  unlockRoom() {
    return '/unlock-room';
  },

  getCompanies() {
    return `/utm/mediums`;
  },
  getSources() {
    return `/utm/sources`;
  },
  getStayingGuests() {
    return '/staying-customers';
  },
  getAllNationality() {
    return '/nationality-statistic';
  },
  getCashFlow() {
    return '/get-cash-payments';
  },
  createExpense() {
    return '/create-payment';
  },

  getAllBranches() {
    return '/branches';
  },
  getCurrentNightAudit() {
    return '/current-branch';
  },
  finishNightAudit() {
    return '/night-audit';
  },

  getRoomAvailability() {
    return `/night-audit`;
  },
  getRoomSummary() {
    return `/room-summary`;
  },
  getRoomLock() {
    return `/room-lock`;
  },

  updateBreakfast(bookingLineId: number) {
    return `/booking-lines/${bookingLineId}/booking-breakfast`;
  },
  undoCheckIn(bookingLineId: number) {
    return `/booking-lines/${bookingLineId}/undo-checkin`;
  },
  undoCheckOut(bookingLineId: number) {
    return `/booking-lines/${bookingLineId}/undo-checkout`;
  },
  getTransactions() {
    return `/get-payments`;
  },

  getAllBookingLines(bookingId?: number) {
    return `/bookings/${bookingId}/lines`;
  },
  uploadConfirmation(bookingLineId: number) {
    return `/booking-lines/${bookingLineId}/attachments`;
  },
  getReservationForecast() {
    return `/weakly-reservation-forecast`;
  },
  getHouseStatus() {
    return `/house-status`;
  },

  getSubHKRooms() {
    return `/hk/sub/rooms`;
  },
  getHKRooms() {
    return `/hk/rooms`;
  },
  shiftAssign() {
    return `/hk/shift-assign`;
  },
  getPmsUsers() {
    return `/pms-users`;
  },
  getListMinibar() {
    return `/services/minibar`;
  },
  hkCheckMinibar() {
    return `/hk/minibar-check`;
  },
  hkStartCleaning() {
    return `/hk/start-cleaning`;
  },
  hkFinishCleaning() {
    return `/hk/end-cleaning`;
  },
  subHKApproveCleaning() {
    return `/hk/sub/cleaning-approve`;
  },
  subHKFillMinibar() {
    return `/hk/sub/minibar-check`;
  },

  getCurrentBranch() {
    return `/current-branch`;
  },
  getLabels() {
    return `/labels`;
  },
  createAndAssignLabel(bookingLineId: number) {
    return `/booking-lines/${bookingLineId}/create-and-assign-label`;
  },
  unassignLabels(bookingLineId: number) {
    return `/booking-lines/${bookingLineId}/unassign-labels`;
  },
  createShiftHandover() {
    return `/handover-shifts`;
  },
  getCurrentShiftHandover() {
    return `/handover-shifts/current-shift`;
  },
  getListShiftHandover() {
    return `/handover-shifts`;
  },
  chargePaymentDebt() {
    return `/payments/pay-debt`;
  },
  scanCustomerInfo() {
    return `/customer-info-ocr`;
  },
  warehouseManagement() {
    return `/stock/products`;
  },
  getTravelAgency(id: number) {
    return `/travel-agencies/${id}`;
  },
  travelAgencies() {
    return `/travel-agencies`;
  },

  // Groups
  getGroupBookings() {
    return `/groups`;
  },
  getSubGroupsDetails() {
    return `/subgroups`;
  },
  getGroupBookingDetail(groupId: number) {
    return `/groups/${groupId}`;
  },
  addSubGroup(groupId: number) {
    return `/groups/${groupId}/add-subgroup`;
  },
  createGroupBooking() {
    return `/groups`;
  },
  updateGroupBooking(groupId: number) {
    return `/groups/${groupId}/update`;
  },
  generateRooming() {
    return `/groups/rooming`;
  },
  updateSubgroup(subGroupId: number) {
    return `/subgroups/${subGroupId}`;
  },
  changeDateSubgroup(groupId: number) {
    return `/groups/${groupId}/change-date`;
  },
  cancelSubgroup(subGroupId: number) {
    return `/subgroups/${subGroupId}/cancel`;
  }
};
