import React, { useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Button, DatePicker, Form, Input, Table, TableProps } from 'antd';
import { useGetRoomAvailability } from 'hooks/useGetRoomAvailability';
import { toUtcTime } from 'utils';
import 'styles/room-availability.scss';
import ConfirmCheckRoomAvailable from 'components/modal/room-available-components/confirm-check-room-available';
import useModal from 'stores/useModal';

const INITIAL_RANGE_DATE = 14;
const today = dayjs().startOf('day');

function RoomAvailability() {
  const [date, setDate] = useState<[Dayjs, Dayjs]>([today, today.add(INITIAL_RANGE_DATE, 'day')]);
  const [rangeDate, setRangeDate] = useState(INITIAL_RANGE_DATE);
  const [inputRangeDate, setInputRangeDate] = useState<string>(INITIAL_RANGE_DATE.toString());
  const [queryParams, setQueryParams] = useState<[Dayjs, number]>([date[0], rangeDate]);
  const { isOpenCheckRoomAvailability, setIsOpenCheckRoomAvailability } = useModal();
  const [dateToQuery, setDateToQuery] = useState<Dayjs | undefined>();
  const [roomTypeToQuery, setRoomTypeToQuery] = useState<string>('');

  const { data: roomAvailability, isFetching } = useGetRoomAvailability(
    String(toUtcTime(queryParams[0], 'YYYY-MM-DD')),
    queryParams[1]
  );

  const onHandleCheckRoomAvailable = (date?: Dayjs, roomType?: string) => {
    setIsOpenCheckRoomAvailability(true);
    setDateToQuery(date);
    setRoomTypeToQuery(roomType ?? '');
  };

  const onCancelCheckRoomAvailable = () => {
    setIsOpenCheckRoomAvailability(false);
  };

  const onHandleClose = () => {
    setIsOpenCheckRoomAvailability(false);
  };

  const handleChangeDate = (value: Dayjs | null) => {
    if (value) {
      const newEndDate = value.add(rangeDate, 'day');
      setDate([value, newEndDate]);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputRangeDate(e.target.value);
  };

  const handleSubmit = () => {
    let inputValue = inputRangeDate;
    if (!inputValue) {
      inputValue = INITIAL_RANGE_DATE.toString();
    }
    const parsedValue = parseInt(inputValue, 10);
    if (!isNaN(parsedValue)) {
      setRangeDate(parsedValue);
      const newEndDate = date[0].add(parsedValue, 'day');
      setDate([date[0], newEndDate]);
      setQueryParams([date[0], parsedValue]);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const columnCleaningRoom: TableProps<any>['columns'] = useMemo(() => {
    if (!date || !date[0].isValid() || !date[1].isValid()) {
      return [];
    }
    function isClickableCell(roomType: string, cellValue: any, totalRooms: number): boolean {
      const nonClickableTypes = new Set([
        'ALM-ALLOTMENT',
        'Total Availability Rooms',
        'Total Availability Rooms(Not subtracted Allotment)'
      ]);
      return !nonClickableTypes.has(roomType) && cellValue !== totalRooms;
    }

    let startDate = dayjs(date[0]);
    const endDate = dayjs(date[1]);
    const columns: TableProps<any>['columns'] = [
      {
        title: 'Loại phòng',
        dataIndex: 'roomType',
        key: 'roomType',
        fixed: 'left',
        width: 355
      },
      {
        title: 'Số lượng phòng',
        dataIndex: 'total_rooms',
        key: 'total_rooms',
        fixed: 'left',
        align: 'center',
        width: 135
      }
    ];
    while (!startDate.isSame(endDate, 'day')) {
      const addedColumn = startDate.format('YYYY-MM-DD');
      columns.push({
        align: 'center',
        title: startDate.format('DD/MM'),
        dataIndex: addedColumn,
        key: addedColumn,
        width: 90,
        render: (text: any, record: any) => {
          const isClickable = isClickableCell(record.roomType, text, record.total_rooms);
          return (
            <p className="m-0 text-right" style={{ display: 'flex', justifyContent: 'center' }}>
              {isClickable ? (
                <a
                  onClick={() => onHandleCheckRoomAvailable(dayjs(addedColumn), record['roomType'])}
                >
                  {text}
                </a>
              ) : (
                text
              )}
            </p>
          );
        },
        onCell: (record: any) => {
          const isClickable = isClickableCell(
            record.roomType,
            record[addedColumn],
            record.total_rooms
          );
          return isClickable
            ? {
                onClick: () => onHandleCheckRoomAvailable(dayjs(addedColumn), record['roomType']),
                style: { cursor: 'pointer' }
              }
            : {};
        }
      });
      startDate = startDate.add(1, 'day');
    }
    return columns;
    // eslint-disable-next-line
  }, [date]);

  return (
    <div className="pms-room-availability">
      <div className="pms-room-availability__timetable">
        <div className="pms-room-availability__filter">
          <div className="pms-room-availability__filter__left">
            <DatePicker
              value={date[0]}
              onChange={value => {
                handleChangeDate(value);
                if (value) {
                  setQueryParams([value, rangeDate]);
                }
              }}
              placeholder="Chọn ngày"
            />
            <Form.Item>
              <Input
                placeholder="Số ngày"
                value={inputRangeDate}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
              />
            </Form.Item>
            <Button
              htmlType="submit"
              className="ant-btn-secondary btn-submit"
              onClick={() => handleSubmit()}
            >
              Tìm kiếm
            </Button>
          </div>
        </div>
        <div className="pms-room-availability__table">
          <Table
            rowKey="roomType"
            loading={isFetching}
            columns={columnCleaningRoom}
            dataSource={date ? roomAvailability : []}
            pagination={false}
            scroll={{ x: 1000, y: 'calc(100vh - 260px)' }}
            bordered
            locale={{
              emptyText: <span className="empty-data">Không có dữ liệu</span>
            }}
          />
        </div>
      </div>
      <ConfirmCheckRoomAvailable
        dateToQuery={dateToQuery}
        roomTypeToQuery={roomTypeToQuery}
        isOpen={isOpenCheckRoomAvailability}
        onCancel={() => onCancelCheckRoomAvailable()}
        onHandleClose={() => onHandleClose()}
      />
    </div>
  );
}

export default RoomAvailability;
