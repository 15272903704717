import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Select, Form } from 'antd';

import { useGetCustomers } from 'hooks/useGetCustomers';

import { PersonalInfoType } from 'services/api/type/room.type';
import { CUSTOMER_TYPE } from 'constants/form';

export const SelectGroup = () => {
  // Using ant select and server side fetch data
  const [search, setSearch] = useState('');
  const { data, isFetching } = useGetCustomers(search, CUSTOMER_TYPE.BUSINESS);

  const [customers, setCustomers] = useState<PersonalInfoType[] | undefined>([]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setCustomers(data);
    }
  }, [data]);

  return (
    <>
      <div className="flex items-center" style={{ gap: '8px' }}>
        <Form.Item label="Đoàn / Công Ty" name="group_partner_id" style={{ flex: 1 }}>
          <Select
            showSearch
            allowClear
            onClear={() => setSearch('')}
            placeholder="Chọn Đoàn"
            optionFilterProp="children"
            loading={isFetching}
            onSearch={value => {
              setSearch(value);
            }}
          >
            {customers?.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {`${item.id} - ${item.name}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
    </>
  );
};
