import _ from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Card,
  Form,
  FormProps,
  Row,
  Col,
  Input,
  // AutoComplete,
  Select,
  DatePicker,
  InputNumber,
  DatePickerProps,
  Button,
  notification,
  Checkbox
} from 'antd';
// import { IdcardOutlined, PhoneFilled } from '@ant-design/icons';
import { CellValueChangedEvent } from 'ag-grid-community';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { useGetCustomers } from 'hooks/useGetCustomers';
import { useGetSources } from 'hooks/useGetSources';
import { useGetCompanies } from 'hooks/useGetCompanies';
import { useGetAvailRoomType } from 'hooks/useGetAvailRoomType';

// import { ReactComponent as IconMale } from 'assets/images/male.svg';
// import { ReactComponent as IconEmail } from 'assets/images/email.svg';
import { MESSAGE_CODE } from 'constants/validate';
import { CUSTOMER_TYPE, VIP_LEVEL_OPTIONS } from 'constants/form';
import { RangeValueType } from 'services/api/type/common.type';
import { getDateTodayMin2PM, getNextDateAt, toUtcTime } from 'utils';
import { getNewGroupCols } from 'constants/ag-grid-table';
import { Pricing } from 'services/api/type/room.type';
import { ParamsGroupBooking, SubGroupID } from 'services/api/type/group.type';
import { createGroupBooking } from 'services/api/module/group.api';
import { getDetailGroupURL } from 'routes/constants';
import 'styles/new-group.scss';

export const disabledLastDeparture = (current: Dayjs, arrival: Dayjs) => {
  // Can not select days after today and today
  return current && current <= arrival;
};

const today = dayjs();
const disabledDate: DatePickerProps['disabledDate'] = current => {
  return current.isBefore(today);
};

function NewGroup() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const gridRef = useRef();
  const arrivalForm = Form.useWatch('first_arrival', form);
  const lastDepartureForm = Form.useWatch('last_departure', form);

  // const [searchName, setSearchName] = useState('');
  const [searchCompany, setSearchCompany] = useState('');
  const [rangeDate, setRangeDate] = useState<RangeValueType>([
    getDateTodayMin2PM(),
    getNextDateAt(1, 12)
  ]);
  const [bookings, setBookings] = useState<any[]>([]);

  const { data: companies } = useGetCompanies();
  const { data: sources } = useGetSources();

  // const { data: customers } = useGetCustomers(
  //   searchName,
  //   CUSTOMER_TYPE.PERSONAL,
  //   !!searchName && searchName.length > 3
  // );
  const { data: dataCompany, isFetching } = useGetCustomers(searchCompany, CUSTOMER_TYPE.BUSINESS);

  const { data: availRoomTypes, isFetching: isFetchingRoomTypes } = useGetAvailRoomType(
    toUtcTime(rangeDate[0], 'YYYY-MM-DD HH:mm:ss'),
    toUtcTime(rangeDate[1], 'YYYY-MM-DD HH:mm:ss')
  );

  const { mutateAsync: mutateCreateGroupBooking, isPending } = useMutation({
    mutationFn: (params: ParamsGroupBooking) => createGroupBooking(params)
  });

  useEffect(() => {
    if (!_.isEmpty(availRoomTypes)) {
      const bookings = availRoomTypes.map(item => {
        return {
          room_type_name: item.room_type_name,
          room_type_id: item.room_type_id,
          available_rooms: item.available_rooms,
          from_date: rangeDate[0],
          to_date: rangeDate[1],
          numberOfRooms: '',
          pax: '',
          rateAmount: '',
          isRateOverride: false
        };
      });
      form.resetFields(['pricelist_id']);
      setBookings(bookings);
    }
  }, [availRoomTypes, form, rangeDate]);

  useEffect(() => {
    if ((gridRef.current as any).api) {
      (gridRef.current as any).api.refreshCells({ force: true });
    }
  }, [bookings]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const handleSearchName = useCallback(
  //   _.debounce((value: string) => {
  //     setSearchName(value);
  //   }, 500),
  //   []
  // );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchCompany = useCallback(
    _.debounce((value: string) => {
      setSearchCompany(value);
    }, 500),
    []
  );

  // const handleSelectCustomerAutoComplete = (customerId: number) => {
  //   const customer = _.find(customers, { id: customerId });

  //   if (customer) {
  //     const data: {
  //       [key: string]: any;
  //     } = {
  //       partner_id: customer.id,
  //       name: customer.name,
  //       gender: customer.gender,
  //       phone: customer.phone,
  //       email: customer.email,
  //       dob: customer.dob ? dayjs(customer.dob) : undefined,
  //       identification: customer.identification,
  //       country_id: customer.country?.id,
  //       state_id: customer.state?.id,
  //       street: customer.street,
  //       comment: customer.comment,
  //       attachments: customer.attachments
  //     };
  //     const truthyValues: any = Object.keys(data)
  //       .filter(key => Boolean(data[key]))
  //       .reduce((cur, next) => {
  //         return {
  //           ...cur,
  //           [next]: data[next]
  //         };
  //       }, {});

  //     form.setFieldValue('booker', truthyValues);
  //   }
  // };

  const handleChangeCellValue = useCallback(
    (event: CellValueChangedEvent) => {
      const newBookings = _.cloneDeep(bookings);
      newBookings[Number(event.rowIndex)] = event.data;
      setBookings(newBookings);
    },
    [bookings]
  );

  const handleChangeArrival = (day: Dayjs) => {
    const lastDeparture = day.add(1, 'day');
    form.setFieldValue('release_date', day);
    form.setFieldValue('last_departure', lastDeparture);
    form.setFieldValue('night', 1);

    const minSelected = day.hour(14).minute(0).second(0).millisecond(0);
    const startDate = day.valueOf() > minSelected.valueOf() ? day : minSelected;
    const endDate = startDate.add(1, 'day').hour(12).minute(0).second(0).millisecond(0);
    setRangeDate([startDate, endDate]);
  };

  const handleChangeDeparture = (day: Dayjs) => {
    const startDate: Dayjs = _.clone(arrivalForm);

    const endDate: Dayjs = _.clone(day);
    const night = endDate
      .set('hour', 23)
      .set('minute', 59)
      .diff(startDate.set('hour', 0).set('minute', 0), 'day');
    form.setFieldValue('night', night);

    setRangeDate([startDate.hour(14).minute(0).second(0), endDate.hour(12).minute(0).second(0)]);
  };

  const handleChangePricing = (value: number) => {
    const newBookings = bookings.map(item => {
      const roomType = _.find(availRoomTypes, { room_type_name: item.room_type_name });
      const rateAmount = _.find(roomType?.pricing, { pricelist_id: value });
      return {
        ...item,
        rateAmount: rateAmount?.price || 0
      };
    });
    setBookings(newBookings);
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    try {
      const sub_group_ids: SubGroupID[] = bookings
        .filter(booking => !_.isEmpty(booking.numberOfRooms) && Number(booking.numberOfRooms) > 0)
        .map(booking => ({
          check_in: booking.from_date.format('YYYY-MM-DD') || '',
          check_out: booking.to_date.format('YYYY-MM-DD') || '',
          room_type_id: booking.room_type_id,
          quantity: Number(booking.numberOfRooms),
          rate_amount: Number(booking.rateAmount),
          pax: Number(booking.pax)
        }));

      const params: ParamsGroupBooking = {
        name: values.name,
        travel_agency_id: values.travel_agency_id,
        first_arrival: values.first_arrival.format('YYYY-MM-DD'),
        last_departure: values.last_departure.format('YYYY-MM-DD'),
        release_date: values.release_date.format('YYYY-MM-DD'),
        sub_group_ids,
        has_breakfast: values.has_breakfast
      };

      ['vip_level', 'medium_id', 'source_id', 'note', 'ref_code'].forEach(key => {
        if (values[key]) {
          _.set(params, key, values[key]);
        }
      });

      const result = await mutateCreateGroupBooking(params);

      notification.success({
        message: 'Đặt phòng thành công'
      });

      navigate(getDetailGroupURL(Number(result.id)));

      // queryClient.invalidateQueries({
      //   queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_AVAIL_ROOM_TYPE]
      // });

      // form.resetFields([
      //   'name',
      //   'travel_agency_id',
      //   'note',
      //   'vip_level',
      //   'medium_id',
      //   'source_id',
      //   'booker',
      //   'ref_code',
      //   'has_breakfast'
      // ]);
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  const newGroupCols = useMemo(() => {
    return getNewGroupCols(arrivalForm, lastDepartureForm);
  }, [arrivalForm, lastDepartureForm]);

  const pricelistOptions = useMemo(() => {
    const standForPriceList: Pricing[] = _.get(availRoomTypes, '0.pricing', []);

    const pricingList = [
      {
        label: `Giá mặc định`,
        value: ''
      },
      ...standForPriceList.map(item => ({
        value: item.pricelist_id,
        label: item.pricelist_name
      }))
    ];

    return pricingList;
  }, [availRoomTypes]);

  // const renderCustomerOptions = useMemo(() => {
  //   return customers?.map(item => ({
  //     label: (
  //       <div className="customer-autocomplete-item">
  //         <div className="flex items-center justify-between">
  //           <div className="flex items-center" style={{ gap: '8px' }}>
  //             <IconMale />
  //             <p className="m-0 truncate" style={{ maxWidth: '200px' }}>
  //               {item.name}
  //             </p>
  //           </div>
  //           <div className="flex items-center" style={{ gap: '8px' }}>
  //             <IdcardOutlined />
  //             <p className="m-0 truncate" style={{ maxWidth: '200px' }}>
  //               {item.identification || '-'}
  //             </p>
  //           </div>
  //         </div>
  //         <div className="flex items-center justify-between">
  //           <div className="flex items-center" style={{ gap: '8px' }}>
  //             <IconEmail />
  //             <p className="m-0 truncate" style={{ maxWidth: '200px' }}>
  //               {item.email || '-'}
  //             </p>
  //           </div>
  //           <div className="flex items-center" style={{ gap: '8px' }}>
  //             <PhoneFilled />
  //             <p className="m-0 truncate" style={{ maxWidth: '200px' }}>
  //               {item.phone || '-'}
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //     ),
  //     value: item.id
  //   }));
  // }, [customers]);

  return (
    <div className="pms-new-group">
      <Card title="Tạo đoàn" style={{ width: '100%' }}>
        <Form
          form={form}
          name="new-group-form"
          layout="horizontal"
          initialValues={{
            name: '',
            booker: null,
            travel_agency_id: '',
            // confirm: '',
            // groupCode: '',
            // status: '',
            vip_level: '',
            medium_id: '',
            source_id: '',
            first_arrival: dayjs(),
            last_departure: dayjs().add(1, 'day'),
            release_date: dayjs(),
            night: 1,
            note: '',
            bookings: [],
            pricelist_id: '',
            ref_code: '',
            has_breakfast: false
          }}
          onFinish={onFinish}
          autoComplete="off"
          wrapperCol={{ span: 18 }}
          labelCol={{ span: 6 }}
        >
          <div className="form-inner">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12} xl={7}>
                <Form.Item
                  name="name"
                  label="Tên đoàn"
                  rules={[
                    {
                      required: true,
                      message: MESSAGE_CODE.REQUIRED_GROUP_NAME
                    }
                  ]}
                >
                  <Input className="w-full" />
                </Form.Item>

                {/* <Form.Item
                  label="Người đặt"
                  name={['booker', 'name']}
                  style={{ flexGrow: 5 }}
                  rules={[
                    {
                      required: true,
                      message: MESSAGE_CODE.REQUIRED_FULL_NAME
                    }
                  ]}
                >
                  <AutoComplete
                    allowClear
                    placeholder="Tìm kiếm tên, phone hoặc tạo mới"
                    popupClassName="autocomplete-customer"
                    onSearch={handleSearchName}
                    onSelect={handleSelectCustomerAutoComplete}
                    options={renderCustomerOptions}
                    popupMatchSelectWidth={false}
                  />
                </Form.Item> */}

                <Form.Item
                  label="TA.Company"
                  name="travel_agency_id"
                  rules={[
                    {
                      required: true,
                      message: MESSAGE_CODE.REQUIRED_TA_COMPANY
                    }
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    onClear={() => setSearchCompany('')}
                    placeholder="Chọn Công ty"
                    optionFilterProp="children"
                    loading={isFetching}
                    onSearch={handleSearchCompany}
                  >
                    {dataCompany?.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {`${item.id} - ${item.name}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item label="Loại giá" name="pricelist_id">
                  <Select options={pricelistOptions} onChange={handleChangePricing} />
                </Form.Item>

                <Form.Item label="Ăn sáng" name="has_breakfast" valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} xl={9}>
                {/* <Form.Item name="confirm" label="Confirm#">
                  <Input className="w-full" />
                </Form.Item>

                <Form.Item name="groupCode" label="Group Code">
                  <Input className="w-full" disabled />
                </Form.Item>

                <Form.Item label="Status" name="status">
                  <Select />
                </Form.Item> */}

                <Form.Item label="VIP level" name="vip_level">
                  <Select options={VIP_LEVEL_OPTIONS} />
                </Form.Item>

                <Form.Item name="medium_id" label="Thị trường">
                  <Select
                    options={companies}
                    showSearch
                    filterOption={(input, option) =>
                      !!option && option.name.toLowerCase().includes(input.toLowerCase())
                    }
                    fieldNames={{ label: 'name', value: 'id' }}
                  />
                </Form.Item>

                <Form.Item name="source_id" label="Chọn nguồn">
                  <Select
                    options={sources}
                    showSearch
                    filterOption={(input, option) =>
                      !!option && option.name.toLowerCase().includes(input.toLowerCase())
                    }
                    fieldNames={{ label: 'name', value: 'id' }}
                  />
                </Form.Item>

                <Form.Item name="ref_code" label="Ref code">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} xl={8}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      name="first_arrival"
                      label="First Arrival"
                      wrapperCol={{ span: 12 }}
                      labelCol={{ span: 12 }}
                    >
                      <DatePicker
                        onChange={handleChangeArrival}
                        disabledDate={disabledDate}
                        allowClear={false}
                      />
                    </Form.Item>

                    <Form.Item
                      name="last_departure"
                      label="Last Departure"
                      wrapperCol={{ span: 12 }}
                      labelCol={{ span: 12 }}
                    >
                      <DatePicker
                        onChange={handleChangeDeparture}
                        disabledDate={current => disabledLastDeparture(current, arrivalForm)}
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="night"
                      label="Night"
                      wrapperCol={{ span: 12 }}
                      labelCol={{ span: 12 }}
                    >
                      <InputNumber className="w-full" readOnly />
                    </Form.Item>

                    <Form.Item
                      name="release_date"
                      label="Release Date"
                      wrapperCol={{ span: 12 }}
                      labelCol={{ span: 12 }}
                    >
                      <DatePicker allowClear={false} disabledDate={disabledDate} />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item name="note" label="Note" style={{ marginLeft: '-4px' }}>
                  <Input.TextArea rows={5} className="w-full" />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="booking-group-table ag-theme-quartz">
            <AgGridReact
              ref={gridRef as any}
              rowData={bookings}
              columnDefs={newGroupCols}
              loadingOverlayComponent={isFetchingRoomTypes}
              loadingCellRenderer={isFetchingRoomTypes}
              onCellValueChanged={handleChangeCellValue}
              stopEditingWhenCellsLoseFocus
            />
          </div>

          <div className="booking-group-actions">
            <Button className="ant-btn-secondary" htmlType="submit" loading={isPending}>
              Tạo đoàn mới
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
}

export default NewGroup;
