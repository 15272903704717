// useListCharacter with useQuery hook, to fetch data from the API api/characters
// and return the data and the status of the request
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import {
  getAllBookings,
  getBookingList,
  getAllBookingLinesByBookingId,
  PAGE_SIZE_BOOKING_LIST
} from 'services/api/module/booking.api';
import { BookingLine, PaginationType } from 'services/api/type/booking.type';
import QUERY_KEYS from 'services/api/queryKeys';
import { toLocalTime } from 'utils';
import useBookingStore from 'stores/useBooking';

export const useGetBooking = (
  filterOptions: any,
  page: number = 1,
  pageSize: number = PAGE_SIZE_BOOKING_LIST
) => {
  const {
    data: dataBookingList,
    status,
    isLoading,
    isFetching
  } = useQuery({
    queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_LIST, page, pageSize],
    queryFn: () => getBookingList(filterOptions, page, pageSize),
    placeholderData: keepPreviousData
  });

  const { setPageBookingList } = useBookingStore();

  useEffect(() => {
    setPageBookingList(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const cookedData = useMemo(() => {
    const hash = {} as any;
    const bookingList: BookingLine[] = _.get(dataBookingList, 'data.data', []);

    bookingList.forEach((item: BookingLine) => {
      item.check_in = toLocalTime(item.check_in);
      item.check_out = toLocalTime(item.check_out);
      if (!hash[item.booking_id]) {
        hash[item.booking_id] = item;
      } else {
        if (_.isEmpty(hash[item.booking_id].expandableRows)) {
          hash[item.booking_id].expandableRows = [_.cloneDeep(hash[item.booking_id])];
        }
        hash[item.booking_id].expandableRows.push(item);
      }
    });
    return Object.values(hash).sort((a: any, b: any) => b.booking_id - a.booking_id);
  }, [dataBookingList]);

  return {
    data: Object.values(cookedData) as BookingLine[],
    pagination: _.get(dataBookingList, 'data.pagination', {}) as PaginationType,
    status,
    isLoading,
    isFetching
  };
};

export const useGetAllBooking = (filterOptions: any, isEnable: boolean = true) => {
  const {
    data: dataBookingList,
    status,
    isLoading,
    isFetching
  } = useQuery({
    queryKey: [QUERY_KEYS.GET_BOOKING_LIST_ALL, filterOptions],
    queryFn: () => getAllBookings(filterOptions),
    enabled:
      isEnable &&
      !!filterOptions.status &&
      (!!filterOptions.check_in_to ||
        !!filterOptions.check_out_to ||
        !!filterOptions.check_in_of_room_available)
  });

  const sortedData = useMemo(() => {
    const bookingList: BookingLine[] = _.get(dataBookingList, 'data.data', []);
    return bookingList.sort((a: any, b: any) => b.booking_id - a.booking_id);
  }, [dataBookingList]);

  return {
    data: sortedData,
    pagination: _.get(dataBookingList, 'data.pagination', {}) as PaginationType,
    status,
    isLoading,
    isFetching
  };
};

export const useGetAllBookingLineByBookingId = (bookingId?: number, isEnable?: boolean) => {
  const {
    data: dataBookingList,
    status,
    isLoading,
    isFetching
  } = useQuery({
    queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_ALL_BOOKING_LINES_BY_BOOKING_ID, bookingId],
    queryFn: () => getAllBookingLinesByBookingId(bookingId),
    enabled: !!bookingId && isEnable
  });

  const cookedData = useMemo(() => {
    const bookingList: BookingLine[] = _.clone(dataBookingList) || [];

    bookingList.forEach((item: BookingLine) => {
      item.check_in = toLocalTime(item.check_in);
      item.check_out = toLocalTime(item.check_out);
    });
    return bookingList.sort((a: BookingLine, b: BookingLine) => b.booking_id - a.booking_id);
  }, [dataBookingList]);

  return {
    data: cookedData,
    status,
    isLoading,
    isFetching
  };
};
