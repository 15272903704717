import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import {
  DatePicker,
  Form,
  FormProps,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
  notification
} from 'antd';
import { useMutation } from '@tanstack/react-query';

import useModal from 'stores/useModal';
import { useGetCustomers } from 'hooks/useGetCustomers';

import { MESSAGE_CODE } from 'constants/validate';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import { EXPENSE_TYPE_OPTIONS } from 'constants/form';
import { CreateExpenseType } from 'services/api/type/report.type';
import { toUtcTime } from 'utils';
import API from 'services/api';

const today = dayjs();
function AddExpenseModal() {
  const [form] = Form.useForm();
  const { isOpenAddExpense, setIsOpenAddExpense } = useModal();
  const [searchName, setSearchName] = useState('');

  const { data: customers, isFetching: isFetchingCustomer } = useGetCustomers(
    searchName,
    'person',
    !!searchName
  );

  const { mutateAsync: mutateCreateExpense, isPending } = useMutation({
    mutationFn: (params: CreateExpenseType) => API.report.createExpense(params)
  });

  useEffect(() => {
    if (!isOpenAddExpense) {
      form.resetFields();
    }
  }, [form, isOpenAddExpense]);

  const handleCancel = () => {
    setIsOpenAddExpense(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchName = useCallback(
    _.debounce((value: string) => {
      setSearchName(value);
    }, 500),
    []
  );

  const handleOk = () => {
    form.submit();
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    try {
      await mutateCreateExpense({
        ...values,
        payment_date: toUtcTime(values.payment_date, 'YYYY-MM-DD HH:mm:ss'),
        payment_type: 'expense'
      });
      setIsOpenAddExpense(false);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_CASH_FLOW]
      });
      notification.success({
        message: 'Tạo phiếu chi thành công'
      });
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  const customerOptions = useMemo(() => {
    return customers?.map(item => ({
      label: item.name,
      value: item.id
    }));
  }, [customers]);

  return (
    <Modal
      title="Phiếu chi tiền mặt"
      className="modal-add-expense"
      centered
      width={400}
      open={isOpenAddExpense}
      okText="Tạo phiếu chi"
      cancelText="Đóng"
      okButtonProps={{ className: 'ant-btn-secondary' }}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={isPending}
      destroyOnClose
    >
      <Form
        form={form}
        name="add-expense-form"
        layout="vertical"
        style={{ width: '100%' }}
        initialValues={{
          customer_id: '',
          payment_date: today,
          expense_type: null,
          note: '',
          amount: 0
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Người nhận"
          name="customer_id"
          rules={[
            {
              required: true,
              message: MESSAGE_CODE.REQUIRED_CUSTOMER
            }
          ]}
        >
          <Select
            showSearch
            allowClear
            filterOption={false}
            onSearch={handleSearchName}
            notFoundContent={isFetchingCustomer ? <Spin size="small" /> : null}
            options={customerOptions}
            popupClassName="select-customer-dropdown"
          />
        </Form.Item>

        <Form.Item label="Ngày chi tiền" name="payment_date">
          <DatePicker format="DD/MM/YYYY" className="w-full" />
        </Form.Item>

        <Form.Item
          label="Nội dung chi"
          name="expense_type"
          rules={[
            {
              required: true,
              message: MESSAGE_CODE.REQUIRED_EXPENSE_TYPE
            }
          ]}
        >
          <Select options={EXPENSE_TYPE_OPTIONS} />
        </Form.Item>

        <Form.Item
          label="Tổng tiền"
          name="amount"
          rules={[
            {
              required: true,
              message: MESSAGE_CODE.REQUIRED_AMOUNT
            }
          ]}
        >
          <InputNumber min={0} className="w-full" />
        </Form.Item>

        <Form.Item label="Ghi chú" name="note">
          <Input.TextArea rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddExpenseModal;
