import _ from 'lodash';
import React, { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Form, FormProps, InputNumber, Modal, notification } from 'antd';
import { useParams } from 'react-router-dom';

import useModalStore from 'stores/useModal';
import useBookingStore from 'stores/useBooking';

import { MESSAGE_CODE } from 'constants/validate';
import { ReactComponent as IconClose } from 'assets/images/close.svg';
import { ParamsGenerateRooming } from 'services/api/type/group.type';
import { generateRooming } from 'services/api/module/group.api';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';

interface Props {
  isShow: boolean;
  onCancel: () => void;
}

function GenerateGroupBookingModal({ isShow, onCancel }: Props) {
  const [form] = Form.useForm();
  const { groupId } = useParams();

  const { setBookingLineId: setBookingLineIdBookingStore } = useBookingStore();
  const { subGroupDetail, setOpenBookingDetail } = useModalStore();

  const { mutateAsync: mutateGenerateRooming, isPending } = useMutation({
    mutationFn: (params: ParamsGenerateRooming) => {
      return generateRooming(params);
    }
  });

  useEffect(() => {
    if (!isShow) {
      form.resetFields();
    }
  }, [form, isShow]);

  const handleChangePassword = () => {
    form.submit();
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    if (!_.isEmpty(subGroupDetail)) {
      try {
        const result = await mutateGenerateRooming({
          sub_group_id: subGroupDetail.id,
          quantity: values.quantity,
          pax: values.pax
        });

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GROUP_BOOKING_DETAIL, Number(groupId)]
        });

        setBookingLineIdBookingStore(result.booking_line_id);
        setOpenBookingDetail(true, result.booking_line_id);

        onCancel();
        form.resetFields();
        // setIsOpenGroupRoomingList(true);
      } catch (err: any) {
        notification.error({
          message: err.error || 'Có lỗi xảy ra'
        });
      }
    }
  };

  return (
    <Modal
      title="Rooming List"
      centered
      width={480}
      open={isShow}
      onCancel={onCancel}
      closeIcon={<IconClose />}
      okText="Xác nhận"
      cancelText="Hủy"
      onOk={handleChangePassword}
      okButtonProps={{ className: 'ant-btn-secondary' }}
      className="modal-generate-group-booking"
      destroyOnClose
      confirmLoading={isPending}
    >
      <Form
        form={form}
        name="generate-group-booking-form"
        layout="horizontal"
        style={{ width: '100%' }}
        initialValues={{
          quantity: '',
          pax: ''
        }}
        wrapperCol={{ span: 16 }}
        labelCol={{ span: 8 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Num Rooms"
          name="quantity"
          rules={[
            {
              required: true,
              message: MESSAGE_CODE.REQUIRED_NUMBER_ROOM
            }
          ]}
        >
          <InputNumber className="w-full" />
        </Form.Item>

        <Form.Item
          label="Num Pax"
          name="pax"
          rules={[
            {
              required: true,
              message: MESSAGE_CODE.REQUIRED_PAX
            }
          ]}
        >
          <InputNumber className="w-full" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default GenerateGroupBookingModal;
