import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';

export const useGetRoomLock = (filterOptions?: any, isEnable: boolean = true) => {
  const { data, isFetching, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_ROOM_LOCK, filterOptions],
    queryFn: () => API.room.getRoomLock(filterOptions),
    enabled: isEnable
  });

  return {
    data,
    isFetching,
    isLoading
  };
};
