import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { Modal, Row, Col, Skeleton, Button, notification, Dropdown } from 'antd';
import {
  PrinterFilled,
  SignatureFilled,
  ReloadOutlined,
  HistoryOutlined,
  EllipsisOutlined
} from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';

import PaymentTab from './payment-tab';
import RoomInfoTab from './room-info-tab';
import AttachmentTab from './attachment-tab';
import GroupTab from './group-tab';

import useBookingStore from 'stores/useBooking';
import useModal from 'stores/useModal';
import { useGetBookingDetail } from 'hooks/useGetBookingDetail';

import {
  BATCH_ACTION_TYPE,
  BookingStatus,
  GroupBookingLineItem,
  ParamsBatchAction
} from 'services/api/type/booking.type';
import { queryClient } from 'index';
import { ACTION_BOOKING_LINE_DETAIL_MODAL } from 'constants/common';
import QUERY_KEYS from 'services/api/queryKeys';
import { ACTION_BOOKING } from 'constants/table';
import API from 'services/api';
import { batchActionBookings } from 'services/api/module/booking.api';
import { ReactComponent as IconEmail } from 'assets/images/email.svg';
import { ReactComponent as IconBedUser } from 'assets/images/bed-user.svg';
// import { ReactComponent as IconDollar } from 'assets/images/dollar.svg';
import { ReactComponent as Logout } from 'assets/images/logout.svg';
import { handleDisableCheckInButton } from 'utils';

const itemsMenu = [
  {
    key: ACTION_BOOKING_LINE_DETAIL_MODAL.HISTORY,
    label: 'Lịch sử'
  },
  {
    key: ACTION_BOOKING_LINE_DETAIL_MODAL.SEND_EMAIL,
    label: 'Gửi email'
  },
  {
    key: ACTION_BOOKING_LINE_DETAIL_MODAL.VIEW_PDF,
    label: 'In hóa đơn'
  },
  {
    key: ACTION_BOOKING_LINE_DETAIL_MODAL.VIEW_CONFIRMATION,
    label: 'In hợp đồng'
  },
  {
    key: ACTION_BOOKING_LINE_DETAIL_MODAL.RELOAD_BOOKING,
    label: 'Làm mới'
  }
];

const itemsMenuGroup = [
  {
    key: ACTION_BOOKING.CHANGE_DATE,
    label: 'Đổi ngày ở'
  },
  // {
  //   key: ACTION_BOOKING.CHANGE_PRICE,
  //   label: 'Đổi giá'
  // },
  {
    key: ACTION_BOOKING.ASSIGN_ROOM,
    label: 'Gán phòng'
  },
  {
    key: ACTION_BOOKING.CANCEL_ASSIGN_ROOM,
    label: 'Bỏ gán phòng'
  },
  {
    key: ACTION_BOOKING.CANCEL_BOOKING,
    label: 'Hủy phòng'
  }
];

function BookingLineDetailModal() {
  const {
    setInfoConfirmModal,
    setConfirmLoading,
    isOpenBookingDetail,
    bookingLineId,
    setOpenBookingDetail,
    setIsOpenViewPDF,
    setIsOpenConfirmationPDF,
    setIsOpenViewTrackingLogs,

    setIsOpenCancelRoom,
    // setIsOpenChangePrice,
    // setIsOpenChangePriceGroup,
    setIsOpenChangeDate,
    setIsOpenCancelAssignRoom
    // setIsOpenPayment
  } = useModal();

  const { data: bookingLine, isFetching } = useGetBookingDetail(bookingLineId);

  const {
    isLoadingBookingDetail,
    checkedListBookingLineId,
    setCheckListBookingLineId,
    setIsActionOnGroup
  } = useBookingStore();

  const isIpadMobile = useMediaQuery({
    query: '(max-width: 991px)'
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  });

  const [tab, setTab] = useState(1);
  const [groupBookingLine, setGroupBookingLine] = useState<GroupBookingLineItem[]>([]);

  const { mutateAsync: mutateSendEmail } = useMutation({
    mutationFn: (bookingId: number) => API.booking.sendEmail(bookingId)
  });

  const { mutateAsync: mutateBatchActionBooking } = useMutation({
    mutationFn: (params: ParamsBatchAction) => batchActionBookings(params)
  });

  useEffect(() => {
    if (!isOpenBookingDetail) {
      setTab(1);
      setGroupBookingLine([]);
      setCheckListBookingLineId([]);
    }
  }, [isOpenBookingDetail, setCheckListBookingLineId]);

  useEffect(() => {
    if (bookingLine) {
      setGroupBookingLine(bookingLine?.group?.booking_lines || []);
    }
  }, [bookingLine]);

  const handleClickMenu = (menu: any) => {
    switch (menu.key) {
      case ACTION_BOOKING_LINE_DETAIL_MODAL.HISTORY:
        handleViewTrackingLogs();
        break;
      case ACTION_BOOKING_LINE_DETAIL_MODAL.SEND_EMAIL:
        handleConfirmSendEmail();
        break;
      case ACTION_BOOKING_LINE_DETAIL_MODAL.VIEW_PDF:
        handleViewPdf();
        break;
      case ACTION_BOOKING_LINE_DETAIL_MODAL.VIEW_CONFIRMATION:
        handleViewConfirmation();
        break;
      case ACTION_BOOKING_LINE_DETAIL_MODAL.RELOAD_BOOKING:
        handleReloadBookingLine();
        break;
      default:
        break;
    }
  };

  // Action group
  const handleClickMenuGroup = (menu: any) => {
    switch (menu.key) {
      case ACTION_BOOKING.CANCEL_BOOKING:
        setIsActionOnGroup(true);
        setIsOpenCancelRoom(true);
        break;
      // case ACTION_BOOKING.CHANGE_PRICE: {
      //   if (checkedListBookingLineId.length === 1) {
      //     setIsOpenChangePrice(true);
      //   } else {
      //     setIsOpenChangePriceGroup(true);
      //   }
      //   break;
      // }
      case ACTION_BOOKING.ASSIGN_ROOM: {
        setInfoConfirmModal(true, {
          title: 'Xác nhận gán phòng tự động',
          onOk: () => {
            setInfoConfirmModal(false);
            handleAutoAssign();
          }
        });
        break;
      }
      case ACTION_BOOKING.CHANGE_DATE: {
        setIsActionOnGroup(true);
        setIsOpenChangeDate(true);
        break;
      }
      case ACTION_BOOKING.CANCEL_ASSIGN_ROOM: {
        setIsActionOnGroup(true);
        setIsOpenCancelAssignRoom(true);
        break;
      }

      default:
        break;
    }
  };

  const handleCheckInGroup = () => {
    setInfoConfirmModal(true, {
      title: 'Xác nhận nhận phòng',
      onOk: async () => {
        setInfoConfirmModal(false);
        setConfirmLoading(true);
        try {
          await mutateBatchActionBooking({
            action: BATCH_ACTION_TYPE.CHECK_IN,
            booking_line_ids: checkedListBookingLineId
          });
          notification.success({
            message: 'Nhận phòng thành công'
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.KEY_ROOM]
          });
        } catch (err: any) {
          notification.error({
            message: err.error || 'Lỗi xảy ra'
          });
        } finally {
          setConfirmLoading(false);
        }
      }
    });
  };

  const handleCheckOutGroup = () => {
    setInfoConfirmModal(true, {
      title: 'Xác nhận trả phòng',
      onOk: async () => {
        setInfoConfirmModal(false);
        setConfirmLoading(true);
        try {
          await mutateBatchActionBooking({
            action: BATCH_ACTION_TYPE.CHECK_OUT,
            booking_line_ids: checkedListBookingLineId
          });
          notification.success({
            message: 'Trả phòng thành công'
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.KEY_ROOM]
          });
        } catch (err: any) {
          notification.error({
            message: err.error || 'Lỗi xảy ra'
          });
        } finally {
          setConfirmLoading(false);
        }
      }
    });
  };

  const handleAutoAssign = async () => {
    try {
      setConfirmLoading(true);

      const selectedBookingLines = bookingLine?.group?.booking_lines.filter(item =>
        checkedListBookingLineId.includes(item.id)
      );

      const unAssignedBookingLineId =
        selectedBookingLines?.filter(item => !item.room_id).map(item => item.id) || [];

      await mutateBatchActionBooking({
        action: BATCH_ACTION_TYPE.AUTO_ASSIGN,
        booking_line_ids: unAssignedBookingLineId
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.KEY_ROOM]
      });

      setIsOpenChangeDate(false);
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    } finally {
      setConfirmLoading(false);
    }
  };
  // Action group

  const handleConfirmSendEmail = () => {
    setInfoConfirmModal(true, {
      title: 'Xác nhận gửi mail',
      okText: 'Gửi',
      onOk: async () => {
        if (bookingLine?.booking_id) {
          try {
            setConfirmLoading(true);
            await mutateSendEmail(bookingLine.booking_id);
            setInfoConfirmModal(false);
            notification.success({
              message: 'Gửi mail thành công'
            });
          } catch (err: any) {
            notification.error({
              message: 'Gửi mail thất bại'
            });
          } finally {
            setConfirmLoading(false);
          }
        }
      }
    });
  };

  const handleViewPdf = () => {
    setIsOpenViewPDF(true);
  };

  const handleViewConfirmation = () => {
    setIsOpenConfirmationPDF(true);
  };

  const handleReloadBookingLine = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL, bookingLine?.booking_line_id]
    });
  };
  const handleViewTrackingLogs = () => {
    setIsOpenViewTrackingLogs(true);
  };

  // const handlePayment = () => {
  //   setIsOpenPayment(true);
  // };

  const menuActionGroup = useMemo(() => {
    const isHaveAssignedRoom = bookingLine?.group?.booking_lines
      .filter(item => checkedListBookingLineId.includes(item.id))
      .every(item => !!item.room_id && item.status === BookingStatus.CONFIRM);

    const isUnAssignedRoom = bookingLine?.group?.booking_lines
      .filter(item => checkedListBookingLineId.includes(item.id))
      .every(item => !item.room_id && item.status === BookingStatus.CONFIRM);

    const isNotCancelRoom = bookingLine?.group?.booking_lines
      .filter(item => checkedListBookingLineId.includes(item.id))
      .every(item => item.status === BookingStatus.CONFIRM);

    const isAllowChangeDate = bookingLine?.group?.booking_lines
      .filter(item => checkedListBookingLineId.includes(item.id))
      .every(
        item => item.status !== BookingStatus.CHECK_OUT && item.status !== BookingStatus.CANCEL
      );

    return itemsMenuGroup.filter(item => {
      if (!isHaveAssignedRoom && item.key === ACTION_BOOKING.CANCEL_ASSIGN_ROOM) {
        return false;
      }

      if (!isUnAssignedRoom && item.key === ACTION_BOOKING.ASSIGN_ROOM) {
        return false;
      }

      if (!isNotCancelRoom && item.key === ACTION_BOOKING.CANCEL_BOOKING) {
        return false;
      }

      if (!isAllowChangeDate && item.key === ACTION_BOOKING.CHANGE_DATE) {
        return false;
      }
      return true;
    });
  }, [bookingLine?.group?.booking_lines, checkedListBookingLineId]);

  const renderContent = () => {
    if (isLoadingBookingDetail) {
      return (
        <Row className="modal-content">
          <Col span={24} md={12}>
            <Skeleton />
          </Col>

          <Col span={24} md={12}>
            <Skeleton />
          </Col>
        </Row>
      );
    }

    if (bookingLine && tab === 1) {
      return <RoomInfoTab bookingLine={bookingLine} />;
    } else if (bookingLine && tab === 2) {
      return <PaymentTab bookingLine={bookingLine} />;
    } else if (bookingLine && tab === 3) {
      return <AttachmentTab bookingLine={bookingLine} />;
    }
  };

  const isShowGroupTab = !_.isEmpty(bookingLine?.group) && !isIpadMobile;

  // ====================
  const isAllowableCheckIn = useMemo(() => {
    const selectedBookingLines = bookingLine?.group?.booking_lines.filter(item =>
      checkedListBookingLineId.includes(item.id)
    );
    return selectedBookingLines?.every(item => item.status === BookingStatus.CONFIRM);
  }, [bookingLine?.group?.booking_lines, checkedListBookingLineId]);

  // ====================
  const isAllowableCheckOut = useMemo(() => {
    const selectedBookingLines = bookingLine?.group?.booking_lines.filter(item =>
      checkedListBookingLineId.includes(item.id)
    );
    return selectedBookingLines?.every(item => item.status === BookingStatus.CHECK_IN);
  }, [bookingLine?.group?.booking_lines, checkedListBookingLineId]);

  // ====================
  const isAllowableShowAction = useMemo(() => {
    const selectedBookingLines = bookingLine?.group?.booking_lines.filter(item =>
      checkedListBookingLineId.includes(item.id)
    );
    return selectedBookingLines?.some(
      item => item.status !== BookingStatus.CANCEL && item.status !== BookingStatus.CHECK_OUT
    );
  }, [bookingLine?.group?.booking_lines, checkedListBookingLineId]);

  return (
    <Modal
      title={null}
      centered
      width={isShowGroupTab ? 1400 : 1100}
      open={isOpenBookingDetail}
      onCancel={() => setOpenBookingDetail(false)}
      footer={null}
      closeIcon={isMobile ? true : null}
      className={classNames('modal-booking-line', {
        'modal-booking-line--info': tab === 1,
        'modal-booking-line--payment': tab === 2
      })}
    >
      {isShowGroupTab && (
        <GroupTab bookingLines={groupBookingLine} activeBookingLineId={bookingLineId} />
      )}

      <div className="flex-grow">
        <div className="ant-modal-header">
          <div className="ant-modal-title">
            <div className="modal-booking-line-tab-header">
              {!_.isEmpty(checkedListBookingLineId) && isAllowableShowAction ? (
                <div className="group-actions">
                  {isAllowableCheckIn && (
                    <Button
                      className="ant-btn-checkin"
                      icon={<IconBedUser />}
                      onClick={handleCheckInGroup}
                      disabled={handleDisableCheckInButton(bookingLine)}
                    >
                      Nhận phòng
                    </Button>
                  )}

                  {isAllowableCheckOut && (
                    <Button
                      className="ant-btn-checkout"
                      onClick={handleCheckOutGroup}
                      icon={<Logout />}
                    >
                      Trả phòng
                    </Button>
                  )}

                  {/* <Button className="ant-btn-pay" icon={<IconDollar />} onClick={handlePayment}>
                    Thanh toán
                  </Button> */}

                  {isAllowableShowAction && (
                    <Dropdown
                      menu={{ items: menuActionGroup, onClick: handleClickMenuGroup }}
                      trigger={['click']}
                      className="menu-action"
                    >
                      <Button className="btn-ellipsis">
                        <EllipsisOutlined />
                      </Button>
                    </Dropdown>
                  )}
                </div>
              ) : (
                <ul className="modal-booking-line-tabs">
                  <li className={tab === 1 ? 'active' : ''} onClick={() => setTab(1)}>
                    Phòng
                  </li>
                  <li className={tab === 2 ? 'active' : ''} onClick={() => setTab(2)}>
                    Thanh toán
                  </li>
                  <li className={tab === 3 ? 'active' : ''} onClick={() => setTab(3)}>
                    File đính kèm
                  </li>
                </ul>
              )}

              <div className="modal-booking-line-action">
                {isMobile && (
                  <Dropdown
                    menu={{ items: itemsMenu, onClick: handleClickMenu }}
                    trigger={['click']}
                    className="menu-action"
                  >
                    <Button className="btn-ellipsis">
                      <EllipsisOutlined />
                    </Button>
                  </Dropdown>
                )}

                {!isMobile && (
                  <>
                    <Button
                      type="link"
                      onClick={handleViewTrackingLogs}
                      style={{ padding: 0, height: '24px' }}
                    >
                      <HistoryOutlined />
                    </Button>
                    <Button
                      type="link"
                      onClick={handleConfirmSendEmail}
                      style={{ padding: 0, height: '24px' }}
                    >
                      <IconEmail />
                    </Button>
                    <Button
                      type="link"
                      onClick={handleViewPdf}
                      style={{ padding: 0, height: '24px' }}
                    >
                      <PrinterFilled color="#000" />
                    </Button>
                    <Button
                      type="link"
                      onClick={handleViewConfirmation}
                      style={{ padding: 0, height: '24px' }}
                    >
                      <SignatureFilled color="#000" />
                    </Button>
                    <Button
                      type="link"
                      onClick={handleReloadBookingLine}
                      style={{ padding: 0, height: '24px' }}
                    >
                      <ReloadOutlined color="#000" spin={isFetching} />
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {renderContent()}
      </div>
    </Modal>
  );
}

export default BookingLineDetailModal;
