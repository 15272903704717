import { axiosInstance } from '../base.api';
import { paths } from '../paths';
import { CurrentNightAuditType } from '../type/night-audit.type';

export const getCurrentNightAudit = (): Promise<CurrentNightAuditType> => {
  return axiosInstance.get(paths.getCurrentNightAudit()).then(item => item.data.data);
};

export const finishNightAudit = (): Promise<any> => {
  return axiosInstance.post(paths.finishNightAudit()).then(item => item.data.data);
};
