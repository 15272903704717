import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';

export const useGetRoomAvailability = (start_date: string, period: number) => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_ROOM_AVAILABILITY, start_date, period],
    queryFn: () => API.report.getRoomAvailability(start_date, period),
    enabled: !!start_date && !!period
  });

  const convertedData = useMemo(() => {
    return Object.keys(data || {}).map(key => ({
      ...data[key],
      roomType: key
    }));
  }, [data]);

  return {
    data: convertedData || [],
    status,
    isLoading,
    isFetching
  };
};
