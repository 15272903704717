import dayjs, { Dayjs } from 'dayjs';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { CustomCellRendererProps } from 'ag-grid-react';
import { Checkbox } from 'antd';

import NumberEditor from 'components/ag-grid/number-editor';
import DatePickerEditor from 'components/ag-grid/datepicker-editor';
import CheckboxEditor from 'components/ag-grid/checkbox-editor';
import GenderPickerEditor from 'components/ag-grid/gender-picker-editor';
import NationalityPickerEditor from 'components/ag-grid/nationality-picker-editor';
import RoomPickerEditor from 'components/ag-grid/room-picker-editor';

const RegexDecimal = /\B(?=(\d{3})+(?!\d))/g;

export const getNewGroupCols = (arrival: Dayjs, departure: Dayjs) => {
  const cols: ColDef[] = [
    {
      field: 'room_type_name',
      headerName: 'Type',
      width: 100,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center'
    },
    {
      field: 'available_rooms',
      headerName: 'Avail',
      width: 100,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center'
    },
    {
      field: 'from_date',
      headerName: 'From date',
      width: 200,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      cellEditor: DatePickerEditor,
      cellEditorParams: {
        arrival,
        departure
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return dayjs(params.data?.from_date).format('DD/MM/YYYY') || '';
      }
    },
    {
      field: 'to_date',
      headerName: 'To date',
      width: 200,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      cellEditor: DatePickerEditor,
      cellEditorParams: {
        arrival,
        departure
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return dayjs(params.data?.to_date).format('DD/MM/YYYY') || '';
      }
    },
    {
      field: 'numberOfRooms',
      headerName: '#Rms',
      width: 100,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      cellEditor: NumberEditor,
      valueFormatter: (params: ValueFormatterParams) => {
        return `${params.data?.numberOfRooms}`.replace(new RegExp(RegexDecimal), ',');
      }
    },
    {
      field: 'pax',
      headerName: '#Pax',
      width: 100,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      cellEditor: NumberEditor,
      valueFormatter: (params: ValueFormatterParams) => {
        return `${params.data?.pax}`.replace(new RegExp(RegexDecimal), ',');
      }
    },
    {
      field: 'isRateOverride',
      headerName: 'Rate override',
      width: 150,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      // singleClickEdit: true,
      cellEditor: CheckboxEditor,
      cellRenderer: (params: CustomCellRendererProps) => {
        const handleChangeOverride = (e: any) => {
          params?.setValue && params.setValue(e.target.checked);
        };
        return <Checkbox checked={params.value} onChange={handleChangeOverride} />;
      }
    },
    {
      field: 'rateAmount',
      headerName: 'Rate amount',
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: params => {
        return params.data?.isRateOverride;
      },
      cellEditor: NumberEditor,
      valueFormatter: (params: ValueFormatterParams) => {
        return `${params.data?.rateAmount}`.replace(new RegExp(RegexDecimal), ',');
      }
    }
  ];
  return cols;
};

export const getGroupRoomingListCols = (startDate: Dayjs, endDate: Dayjs) => {
  const cols: ColDef[] = [
    {
      field: 'seq',
      headerName: 'Seq',
      width: 100,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      checkboxSelection: true,
      valueGetter: params => {
        return (params.node?.rowIndex || 0) + 1;
      }
    },
    {
      field: 'partner_name',
      headerName: 'Name',
      width: 150,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true
    },
    {
      field: 'gender',
      headerName: 'M_F',
      width: 120,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      cellEditor: GenderPickerEditor,
      cellRenderer: (params: CustomCellRendererProps) => {
        const handleChangeOverride = (value: any) => {
          params?.setValue && params.setValue(value);
        };
        return <GenderPickerEditor value={params.value} onValueChange={handleChangeOverride} />;
      }
    },
    {
      field: 'dob',
      headerName: 'Date Birth',
      width: 120,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      cellEditor: DatePickerEditor,
      valueFormatter: (params: ValueFormatterParams) => {
        return dayjs(params.data?.dob).format('DD/MM/YYYY') || '';
      }
    },
    {
      field: 'country_id',
      headerName: 'Nationality',
      width: 180,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      cellEditor: NationalityPickerEditor,
      cellRenderer: (params: CustomCellRendererProps) => {
        const handleChangeOverride = (value: any) => {
          params?.setValue && params.setValue(value);
        };
        return (
          <NationalityPickerEditor value={params.value} onValueChange={handleChangeOverride} />
        );
      }
    },
    {
      field: 'contact_address_complete',
      headerName: 'Address',
      width: 200,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true
    },
    {
      field: 'room_id',
      headerName: 'Room',
      width: 160,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      cellEditor: RoomPickerEditor,
      cellEditorParams: {
        startDate,
        endDate
      },
      cellRenderer: (params: CustomCellRendererProps) => {
        const handleChangeOverride = (value: any) => {
          params?.setValue && params.setValue(value);
        };
        return (
          <RoomPickerEditor
            startDate={startDate}
            endDate={endDate}
            value={params.value}
            onValueChange={handleChangeOverride}
          />
        );
      }
    },
    {
      field: 'adult',
      headerName: 'Adult',
      width: 80,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      cellEditor: NumberEditor,
      valueFormatter: (params: ValueFormatterParams) => {
        return `${params.data?.adult}`.replace(new RegExp(RegexDecimal), ',');
      }
    },
    {
      field: 'child',
      headerName: 'Child',
      width: 80,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      cellEditor: NumberEditor,
      valueFormatter: (params: ValueFormatterParams) => {
        return `${params.data?.child}`.replace(new RegExp(RegexDecimal), ',');
      }
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      width: 150,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true
    }
  ];
  return cols;
};
