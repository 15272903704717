import { useQuery } from '@tanstack/react-query';
import QUERY_KEYS from 'services/api/queryKeys';
import { getAllTrackingLogs } from 'services/api/module/tracking-logs.api';

export const useGetTrackingLogs = (bookingLineId?: number) => {
  const { data, isLoading, isFetched, error, refetch } = useQuery({
    queryKey: [QUERY_KEYS.GET_TRACKING_LOGS, bookingLineId],
    queryFn: () => getAllTrackingLogs(Number(bookingLineId)),
    enabled: !!bookingLineId
  });

  return {
    data,
    isLoading,
    isFetched,
    error,
    refetch
  };
};
