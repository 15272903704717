import { axiosInstance } from 'services/api/base.api';
import { paths } from 'services/api/paths';

export const getAllTrackingLogs = async (bookingLineId: number) => {
  try {
    return axiosInstance.get(paths.getTracking(bookingLineId)).then(response => response.data.data);
  } catch (error) {
    throw error; // Propagate the error back to the caller
  }
};
